/**
 * Automatically Generated - Do not edit manually.
 * see {@link lib_development/generators/pinpoint/components/components_generator.rb}.
 * run `rails g pinpoint:components -f` to regenerate
 */

import { lazy } from 'react';

// app/views/admin/jobs/sign_off/index.html.erb:33:37
const AdminApprovalWorkflowWidget = lazy(() => import('./admin/ApprovalWorkflowWidget' ));

// app/views/admin/candidates/index.html.erb:17:31
const AdminCandidates = lazy(() => import('./admin/Candidates' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:49:31
// app/views/admin/draft_careers_sites/edit.html.erb:84:35
const AdminCareersSite = lazy(() => import('./admin/CareersSite' ));

// app/views/admin/custom_fields/index.html.erb:7:29
const AdminCustomFields = lazy(() => import('./admin/CustomFields' ));

// app/views/admin/dashboard/show.html.erb:26:29
const AdminDashboard = lazy(() => import('./admin/Dashboard' ));

// app/views/admin/employee_dashboard/settings/show.html.erb:4:25
// app/views/admin/employee_dashboard/users/show.html.erb:4:25
const AdminEmployeeDashboard = lazy(() => import('./admin/EmployeeDashboard' ));

// app/views/admin/facebook_configurations/show.html.erb:9:29
const AdminFacebookConfiguration = lazy(() => import('./admin/FacebookConfiguration' ));

// app/views/admin/group_assessments/show.html.erb:5:27
const AdminGroupAssessment = lazy(() => import('./admin/GroupAssessment' ));

// app/views/admin/interviews/show.html.erb:75:29
const AdminInterview = lazy(() => import('./admin/Interview' ));

// app/views/admin/job_board_postings/index.html.erb:8:27
const AdminJobBoardPostings = lazy(() => import('./admin/JobBoardPostings' ));

// app/views/admin/talent_pipelines/job_seekers/index.html.erb:41:31
const AdminJobSeekers = lazy(() => import('./admin/JobSeekers' ));

// app/views/admin/wizards/show.html.erb:10:25
const AdminNewUserWizard = lazy(() => import('./admin/NewUserWizard' ));

// app/views/admin/approvals/all_approvals_jobs/index.html.erb:5:27
// app/views/admin/approvals/all_approvals_offers/index.html.erb:5:27
// app/views/admin/approvals/all_approvals_requisitions/index.html.erb:5:27
const AdminApprovalsAllApprovals = lazy(() => import('./admin/approvals/AllApprovals' ));

// app/views/admin/approvals/jobs/index.html.erb:5:27
// app/views/admin/approvals/offers/index.html.erb:5:27
// app/views/admin/approvals/requisitions/index.html.erb:5:27
const AdminApprovalsMyApprovals = lazy(() => import('./admin/approvals/MyApprovals' ));

// app/views/admin/automations/index.html.erb:5:27
const AdminAutomationsAutomationsRouter = lazy(() => import('./admin/automations/AutomationsRouter' ));

// app/views/admin/candidate_surveys_configuration/index.html.erb:5:27
const AdminCandidateSurveysConfiguration = lazy(() => import('./admin/candidate_surveys/Configuration' ));

// app/views/admin/candidates/show.html.erb:26:27
const AdminCandidatesShow = lazy(() => import('./admin/candidates/Show' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:7:31
// app/views/admin/draft_careers_sites/edit.html.erb:41:35
const AdminCareersSiteActiveToggle = lazy(() => import('./admin/careersSite/ActiveToggle' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:67:31
// app/views/admin/draft_careers_sites/edit.html.erb:102:36
const AdminCareersSiteCareersCustomCss = lazy(() => import('./admin/careersSite/CareersCustomCSS' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:121:34
const AdminCareersSiteCareersCustomConversionPixel = lazy(() => import('./admin/careersSite/CareersCustomConversionPixel' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:43:31
// app/views/admin/draft_careers_sites/edit.html.erb:77:35
const AdminCareersSiteCareersCustomHomeLink = lazy(() => import('./admin/careersSite/CareersCustomHomeLink' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:25:31
// app/views/admin/draft_careers_sites/edit.html.erb:59:35
const AdminCareersSiteCareersCustomInterestText = lazy(() => import('./admin/careersSite/CareersCustomInterestText' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:34:31
// app/views/admin/draft_careers_sites/edit.html.erb:68:35
const AdminCareersSiteCareersCustomNextStepsText = lazy(() => import('./admin/careersSite/CareersCustomNextStepsText' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:80:32
const AdminCareersSiteCareersCustomRobots = lazy(() => import('./admin/careersSite/CareersCustomRobots' ));

// app/views/admin/draft_careers_sites/edit.html.erb:29:35
// app/views/admin/draft_careers_sites/edit.html.erb:96:36
// app/views/admin/draft_careers_sites/edit.html.erb:102:36
const AdminCareersSiteCareersDraftConfirm = lazy(() => import('./admin/careersSite/CareersDraftConfirm' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:128:32
const AdminCareersSiteCareersFacebookPixelDomainVerification = lazy(() => import('./admin/careersSite/CareersFacebookPixelDomainVerification' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:102:32
const AdminCareersSiteCareersIndeedConversionPixel = lazy(() => import('./admin/careersSite/CareersIndeedConversionPixel' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:96:32
const AdminCareersSiteCareersLinkedinConversionPixel = lazy(() => import('./admin/careersSite/CareersLinkedinConversionPixel' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:19:31
// app/views/admin/draft_careers_sites/edit.html.erb:53:35
const AdminCareersSiteCareersNavigation = lazy(() => import('./admin/careersSite/CareersNavigation' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:13:31
// app/views/admin/careers_sites/_enabled_form.html.erb:80:32
// app/views/admin/careers_sites/_enabled_form.html.erb:88:34
// app/views/admin/careers_sites/_enabled_form.html.erb:96:32
// app/views/admin/careers_sites/_enabled_form.html.erb:102:32
// app/views/admin/careers_sites/_enabled_form.html.erb:108:32
// app/views/admin/careers_sites/_enabled_form.html.erb:114:32
// app/views/admin/careers_sites/_enabled_form.html.erb:121:34
// app/views/admin/careers_sites/_enabled_form.html.erb:128:32
// app/views/admin/draft_careers_sites/edit.html.erb:47:35
const AdminCareersSiteCareersPages = lazy(() => import('./admin/careersSite/CareersPages' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:114:32
const AdminCareersSiteCareersTalentConversionPixel = lazy(() => import('./admin/careersSite/CareersTalentConversionPixel' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:108:32
const AdminCareersSiteCareersZipRecruiterConversionPixel = lazy(() => import('./admin/careersSite/CareersZipRecruiterConversionPixel' ));

// app/views/admin/draft_careers_sites/edit.html.erb:17:33
const AdminCareersSiteCreateDraftCareersSite = lazy(() => import('./admin/careersSite/CreateDraftCareersSite' ));

// app/views/admin/careers_sites/_disabled_form.html.erb:1:25
const AdminCareersSiteDisabledForm = lazy(() => import('./admin/careersSite/DisabledForm' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:55:31
// app/views/admin/draft_careers_sites/edit.html.erb:90:35
const AdminCareersSiteFavicon = lazy(() => import('./admin/careersSite/Favicon' ));

// app/views/admin/careers_sites/edit.html.erb:16:31
const AdminCareersSiteHeader = lazy(() => import('./admin/careersSite/Header' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:88:34
const AdminCareersSiteMaxApplicationsWarning = lazy(() => import('./admin/careersSite/MaxApplicationsWarning' ));

// app/views/admin/careers_sites/_enabled_form.html.erb:61:31
// app/views/admin/draft_careers_sites/edit.html.erb:96:36
const AdminCareersSiteOpenGraphForm = lazy(() => import('./admin/careersSite/openGraph/Form' ));

// app/views/admin/data_protection_policies/edit.html.erb:12:29
const AdminCompaniesDataRetentionPolicies = lazy(() => import('./admin/companies/DataRetentionPolicies' ));

// app/views/admin/company/preferences/show.html.erb:11:29
const AdminCompaniesPreferences = lazy(() => import('./admin/companies/Preferences' ));

// app/views/admin/company/integrations/show.html.erb:27:25
const AdminCompaniesIntegrationsCronofyEnterpriseConnect = lazy(() => import('./admin/companies/integrations/CronofyEnterpriseConnect' ));

// app/views/shared/companies/fieldsets/_info.html.erb:80:39
const AdminCompanyDetailsCustomDomainRedirects = lazy(() => import('./admin/company/details/CustomDomainRedirects' ));

// app/views/admin/company/themes/edit.html.erb:11:29
const AdminCompanyThemesEditThemes = lazy(() => import('./admin/company/themes/EditThemes' ));

// app/views/admin/company/equality_monitorings/show.html.erb:11:29
const AdminEqualityMonitoringConfiguration = lazy(() => import('./admin/equalityMonitoring/Configuration' ));

// app/views/admin/group_assessments/index.html.erb:5:27
const AdminGroupAssessmentsTable = lazy(() => import('./admin/groupAssessments/Table' ));

// app/views/admin/insights/candidate_surveys/show.html.erb:14:35
// app/views/admin/insights/candidates/show.html.erb:10:31
// app/views/admin/insights/equality_monitorings/show.html.erb:10:31
// app/views/admin/insights/interviews/show.html.erb:10:31
// app/views/admin/insights/jobs/show.html.erb:10:31
// app/views/admin/insights/users/show.html.erb:10:31
// app/views/admin/jobs/insights/index.html.erb:17:33
// app/views/admin/jobs/insights/index.html.erb:50:40
// app/views/admin/jobs/insights/index.html.erb:60:40
// app/views/admin/jobs/insights/index.html.erb:61:40
// app/views/admin/jobs/insights/index.html.erb:62:40
// app/views/admin/jobs/insights/index.html.erb:63:40
// app/views/admin/jobs/insights/index.html.erb:64:40
// app/views/admin/jobs/insights/index.html.erb:65:40
// app/views/admin/jobs/insights/index.html.erb:66:40
const AdminInsightsFilters = lazy(() => import('./admin/insights/Filters' ));

// app/views/admin/insights/insights_pages/index.html.erb:7:30
const AdminInsightsInsightsHub = lazy(() => import('./admin/insights/InsightsHub' ));

// app/views/admin/insights/reports/show.html.erb:5:27
const AdminInsightsReports = lazy(() => import('./admin/insights/Reports' ));

// app/views/admin/insights/candidate_surveys/show.html.erb:39:35
const AdminInsightsCandidateSurveysExport = lazy(() => import('./admin/insights/candidateSurveys/Export' ));

// app/views/admin/insights/insights_pages/show.html.erb:5:25
const AdminInsightsInsightsHubDisplayInsights = lazy(() => import('./admin/insights/insightsHub/DisplayInsights' ));

// app/views/admin/integrations/edit.html.erb:4:25
const AdminIntegrationsEditIntegration = lazy(() => import('./admin/integrations/EditIntegration' ));

// app/views/admin/integration_marketplace/index.html.erb:4:25
const AdminIntegrationsIntegrationMarketplace = lazy(() => import('./admin/integrations/IntegrationMarketplace' ));

// app/views/admin/integrations/index.html.erb:4:25
const AdminIntegrationsIntegrationsManager = lazy(() => import('./admin/integrations/IntegrationsManager' ));

// app/views/admin/dashboard/show.html.erb:17:29
const AdminInternsProgrammeDashboard = lazy(() => import('./admin/internsProgramme/Dashboard' ));

// app/views/admin/applications/index.html.erb:13:27
const AdminJobApplicationsIndex = lazy(() => import('./admin/jobApplications/Index' ));

// app/views/admin/talent_pipelines/job_seekers/show.html.erb:74:29
const AdminJobSeekersJobSeekerSecondaryTabs = lazy(() => import('./admin/jobSeekers/JobSeekerSecondaryTabs' ));

// app/views/admin/talent_pipelines/job_seekers/share.html.erb:14:31
const AdminJobSeekersShare = lazy(() => import('./admin/jobSeekers/Share' ));

// app/views/admin/talent_pipelines/job_seekers/scorecard_ratings.html.erb:16:29
const AdminJobSeekersScorecardsScorecardReviews = lazy(() => import('./admin/jobSeekers/scorecards/ScorecardReviews' ));

// app/views/admin/talent_pipelines/job_seekers/show.html.erb:38:37
const AdminJobSeekersShowOverview = lazy(() => import('./admin/jobSeekers/show/Overview' ));

// app/views/admin/jobs/applications/new.html.erb:13:29
const AdminJobsApplicationForm = lazy(() => import('./admin/jobs/ApplicationForm' ));

// app/views/admin/jobs/sign_off/index.html.erb:45:37
const AdminJobsDraftApproval = lazy(() => import('./admin/jobs/DraftApproval' ));

// app/views/admin/jobs/facebook_adsets/edit.html.erb:8:27
// app/views/admin/jobs/facebook_adsets/new.html.erb:8:27
const AdminJobsFacebookAdsets = lazy(() => import('./admin/jobs/FacebookAdsets' ));

// app/views/admin/jobs/facebook_adverts/_form.html.erb:29:31
const AdminJobsFacebookAdvert = lazy(() => import('./admin/jobs/FacebookAdvert' ));

// app/views/admin/facebook_adverts/index.html.erb:10:33
// app/views/admin/jobs/adverts/index.html.erb:27:37
// app/views/admin/jobs/facebook_campaigns/index.html.erb:15:31
const AdminJobsFacebookCampaigns = lazy(() => import('./admin/jobs/FacebookCampaigns' ));

// app/views/admin/jobs/group_interviews/index.html.erb:13:29
const AdminJobsGroupInterviews = lazy(() => import('./admin/jobs/GroupInterviews' ));

// app/views/admin/jobs/index.html.erb:7:29
const AdminJobsIndex = lazy(() => import('./admin/jobs/Index' ));

// app/views/admin/jobs/interviews/index.html.erb:13:29
const AdminJobsInterviews = lazy(() => import('./admin/jobs/Interviews' ));

// app/views/admin/jobs/sign_off/index.html.erb:23:39
const AdminJobsJobInformation = lazy(() => import('./admin/jobs/JobInformation' ));

// app/views/admin/jobs/job_postings/index.html.erb:13:31
const AdminJobsJobPostings = lazy(() => import('./admin/jobs/JobPostings' ));

// app/views/admin/jobs/linked_in_postings/index.html.erb:17:27
const AdminJobsLinkedInPostings = lazy(() => import('./admin/jobs/LinkedInPostings' ));

// app/views/admin/jobs/offer_letters/edit.html.erb:13:29
const AdminJobsOfferLetterTemplateEdit = lazy(() => import('./admin/jobs/OfferLetterTemplateEdit' ));

// app/views/admin/jobs/positions/index.html.erb:13:29
const AdminJobsPositions = lazy(() => import('./admin/jobs/Positions' ));

// app/views/admin/jobs/scorecard_ratings/index.html.erb:13:29
const AdminJobsScorecardReviews = lazy(() => import('./admin/jobs/ScorecardReviews' ));

// app/views/admin/jobs/share.html.erb:13:29
const AdminJobsShare = lazy(() => import('./admin/jobs/Share' ));

// app/views/admin/jobs/_title_header.html.erb:1:25
const AdminJobsTitleHeader = lazy(() => import('./admin/jobs/TitleHeader' ));

// app/views/admin/jobs/job_boards/index.html.erb:13:31
const AdminJobsAdvertsJobBoards = lazy(() => import('./admin/jobs/adverts/JobBoards' ));

// app/views/admin/jobs/applications/_applicant_information.html.erb:20:25
const AdminJobsApplicationsApplicationShow = lazy(() => import('./admin/jobs/applications/ApplicationShow' ));

// app/views/admin/jobs/applications/index.html.erb:15:29
// app/views/admin/jobs/stages/applications/index.html.erb:15:29
const AdminJobsApplicationsApplications = lazy(() => import('./admin/jobs/applications/Applications' ));

// app/views/admin/jobs/applications/_stage_links.html.erb:1:25
const AdminJobsApplicationsJobApplicationsNav = lazy(() => import('./admin/jobs/applications/JobApplicationsNav' ));

// app/views/admin/jobs/applications/lanes.html.erb:17:27
const AdminJobsApplicationsIndexLanes = lazy(() => import('./admin/jobs/applications/index/Lanes' ));

// app/views/admin/jobs/facebook_adverts/_form.html.erb:10:29
// app/views/admin/jobs/facebook_adverts/show.html.erb:25:33
// app/views/platform/facebook/adverts/show.html.erb:8:29
const AdminJobsFacebookAdvertFacebookPreview = lazy(() => import('./admin/jobs/facebookAdvert/FacebookPreview' ));

// app/views/admin/jobs/facebook_adverts/show.html.erb:12:31
const AdminJobsFacebookAdvertReports = lazy(() => import('./admin/jobs/facebookAdvert/Reports' ));

// app/views/admin/jobs/_form.html.erb:1:25
const AdminJobsFormsJobForm = lazy(() => import('./admin/jobs/forms/JobForm' ));

// app/views/admin/jobs/group_interviews/candidates.html.erb:13:29
// app/views/admin/jobs/group_interviews/sessions.html.erb:13:29
// app/views/admin/jobs/group_interviews/show.html.erb:13:29
const AdminJobsGroupInterviewsShow = lazy(() => import('./admin/jobs/groupInterviews/Show' ));

// app/views/admin/jobs/integrations/cv_library_postings/edit.html.erb:19:29
const AdminJobsIntegrationsEditCvLibraryPosting = lazy(() => import('./admin/jobs/integrations/EditCvLibraryPosting' ));

// app/views/admin/jobs/integrations/reed_postings/edit.html.erb:19:29
const AdminJobsIntegrationsEditReedPosting = lazy(() => import('./admin/jobs/integrations/EditReedPosting' ));

// app/views/admin/jobs/integrations/seek_postings/edit.html.erb:19:29
const AdminJobsIntegrationsEditSeekPosting = lazy(() => import('./admin/jobs/integrations/EditSeekPosting' ));

// app/views/admin/jobs/integrations/total_jobs_postings/edit.html.erb:19:29
const AdminJobsIntegrationsEditTotalJobsPosting = lazy(() => import('./admin/jobs/integrations/EditTotalJobsPosting' ));

// app/views/admin/jobs/integrations/index.html.erb:19:29
const AdminJobsIntegrationsIntegrationsIndex = lazy(() => import('./admin/jobs/integrations/IntegrationsIndex' ));

// app/views/admin/jobs/integrations/cv_library_postings/new.html.erb:19:29
const AdminJobsIntegrationsNewCvLibraryPosting = lazy(() => import('./admin/jobs/integrations/NewCvLibraryPosting' ));

// app/views/admin/jobs/integrations/reed_postings/new.html.erb:19:29
const AdminJobsIntegrationsNewReedPosting = lazy(() => import('./admin/jobs/integrations/NewReedPosting' ));

// app/views/admin/jobs/integrations/seek_postings/new.html.erb:19:29
const AdminJobsIntegrationsNewSeekPosting = lazy(() => import('./admin/jobs/integrations/NewSeekPosting' ));

// app/views/admin/jobs/integrations/total_jobs_postings/new.html.erb:19:29
const AdminJobsIntegrationsNewTotalJobsPosting = lazy(() => import('./admin/jobs/integrations/NewTotalJobsPosting' ));

// app/views/admin/jobs/job_board_postings/naukri_postings/edit.html.erb:19:29
// app/views/admin/jobs/job_board_postings/naukri_postings/new.html.erb:19:29
const AdminJobsJobBoardPostingsNaukriPostingForm = lazy(() => import('./admin/jobs/jobBoardPostings/naukri/PostingForm' ));

// app/views/admin/jobs/job_seekers/index.html.erb:34:31
const AdminJobsJobSeekersJobSeekers = lazy(() => import('./admin/jobs/jobSeekers/JobSeekers' ));

// app/views/admin/jobs/qrcode.html.erb:13:29
const AdminJobsShareQrCodes = lazy(() => import('./admin/jobs/share/QrCodes' ));

// app/views/admin/jobs/sharing_links/index.html.erb:13:29
const AdminJobsShareSharingLinks = lazy(() => import('./admin/jobs/share/SharingLinks' ));

// app/views/admin/jobs/stages/edit.html.erb:13:29
const AdminJobsStagesEdit = lazy(() => import('./admin/jobs/stages/Edit' ));

// app/views/admin/jobs/stages/configurations/show.html.erb:15:33
const AdminJobsStagesVideoInterview = lazy(() => import('./admin/jobs/stages/VideoInterview' ));

// app/views/admin/jobs/stages/interview_invites/index.html.erb:17:35
const AdminJobsStagesInterviewInvitesVideoInvites = lazy(() => import('./admin/jobs/stages/interviewInvites/VideoInvites' ));

// app/views/admin/jobs/job_seekers/index.html.erb:17:31
const AdminJobsTalentPipelinesJobSeekers = lazy(() => import('./admin/jobs/talentPipelines/JobSeekers' ));

// app/views/admin/jobs/teams/show.html.erb:13:29
const AdminJobsTeamJobPermissions = lazy(() => import('./admin/jobs/team/JobPermissions' ));

// app/views/layouts/admin/_desktop_nav.html.erb:32:33
const AdminLayoutAwayStatusTag = lazy(() => import('./admin/layout/AwayStatusTag' ));

// app/views/layouts/application.html.erb:26:27
// app/views/layouts/fullscreen.html.erb:21:25
const AdminLayoutFooter = lazy(() => import('./admin/layout/Footer' ));

// app/views/layouts/application.html.erb:36:27
const AdminLayoutMobileNavigation = lazy(() => import('./admin/layout/MobileNavigation' ));

// app/views/layouts/fullscreen/_header.html.erb:15:35
const AdminLayoutNotifications = lazy(() => import('./admin/layout/Notifications' ));

// app/views/layouts/admin/_desktop_nav.html.erb:45:33
// app/views/layouts/fullscreen/_header.html.erb:21:31
// app/views/layouts/platform/_desktop_nav.html.erb:20:33
// app/views/layouts/referrals/_desktop_nav.html.erb:26:33
const AdminLayoutProfileNav = lazy(() => import('./admin/layout/ProfileNav' ));

// app/views/layouts/admin/_desktop_nav.html.erb:28:37
const AdminLayoutDesktopDesktopNotifications = lazy(() => import('./admin/layout/desktop/DesktopNotifications' ));

// app/views/layouts/admin/_desktop_nav.html.erb:24:39
const AdminLayoutDesktopDesktopTasks = lazy(() => import('./admin/layout/desktop/DesktopTasks' ));

// app/views/admin/onboarding/candidates/archived.html.erb:23:31
// app/views/admin/onboarding/candidates/legacy.html.erb:23:31
const AdminOnboardingCandidates = lazy(() => import('./admin/onboarding/Candidates' ));

// app/views/admin/onboarding/dashboard/show.html.erb:5:27
const AdminOnboardingDashboard = lazy(() => import('./admin/onboarding/Dashboard' ));

// app/views/admin/onboarding/portals/index.html.erb:5:27
const AdminOnboardingPortals = lazy(() => import('./admin/onboarding/Portals' ));

// app/views/admin/onboarding/processes/index.html.erb:4:27
const AdminOnboardingProcesses = lazy(() => import('./admin/onboarding/Processes' ));

// app/views/admin/onboarding/processes/show.html.erb:5:25
const AdminOnboardingStages = lazy(() => import('./admin/onboarding/Stages' ));

// app/views/admin/onboarding/workflows/index.html.erb:5:27
const AdminOnboardingWorkflows = lazy(() => import('./admin/onboarding/Workflows' ));

// app/views/admin/onboarding/candidates/settings.html.erb:30:31
const AdminOnboardingCandidatesSettings = lazy(() => import('./admin/onboarding/candidates/Settings' ));

// app/views/admin/onboarding/candidates/comments/show.html.erb:25:29
// app/views/admin/onboarding/candidates/finishes/show.html.erb:25:29
// app/views/admin/onboarding/candidates/messages/show.html.erb:25:29
// app/views/admin/onboarding/candidates/reviews/show.html.erb:25:29
// app/views/admin/onboarding/candidates/setups/show.html.erb:25:29
// app/views/admin/onboarding/candidates/show.html.erb:26:29
// app/views/admin/onboarding/candidates/tasks/show.html.erb:25:29
const AdminOnboardingCandidatesShow = lazy(() => import('./admin/onboarding/candidates/Show' ));

// app/views/admin/onboarding/workflows/templates/show.html.erb:46:29
const AdminOnboardingPreviewsWorkflow = lazy(() => import('./admin/onboarding/previews/Workflow' ));

// app/views/admin/onboarding/candidates/index.html.erb:5:27
const AdminOnboardingProcessesCandidates = lazy(() => import('./admin/onboarding/processes/Candidates' ));

// app/views/admin/onboarding/step_templates/index.html.erb:6:25
const AdminOnboardingProcessesStepsStepLibrary = lazy(() => import('./admin/onboarding/processes/steps/StepLibrary' ));

// app/views/admin/onboarding/workflows/templates/index.html.erb:5:27
const AdminOnboardingWorkflowsTemplates = lazy(() => import('./admin/onboarding/workflows/Templates' ));

// app/views/admin/organisation/permissions/show.html.erb:4:25
const AdminOrganisationPermissions = lazy(() => import('./admin/organisation/Permissions' ));

// app/views/admin/organisation/job_posting_locations/show.html.erb:4:25
const AdminOrganisationPostingLocations = lazy(() => import('./admin/organisation/PostingLocations' ));

// app/views/admin/organisation/structures/show.html.erb:4:25
const AdminOrganisationStructure = lazy(() => import('./admin/organisation/Structure' ));

// app/views/admin/position_management/positions/archived.html.erb:14:31
// app/views/admin/position_management/positions/index.html.erb:14:31
const AdminPositionManagementPositions = lazy(() => import('./admin/positionManagement/Positions' ));

// app/views/admin/referrals/index.html.erb:4:25
const AdminReferralsReferrals = lazy(() => import('./admin/referrals/Referrals' ));

// app/views/admin/requisitions/edit.html.erb:7:27
const AdminRequisitionsEdit = lazy(() => import('./admin/requisitions/Edit' ));

// app/views/admin/requisitions/new.html.erb:7:27
const AdminRequisitionsRequisitionForm = lazy(() => import('./admin/requisitions/RequisitionForm' ));

// app/views/admin/requisitions/index.html.erb:7:27
const AdminRequisitionsRequisitionsIndex = lazy(() => import('./admin/requisitions/RequisitionsIndex' ));

// app/views/admin/requisitions/show.html.erb:18:35
const AdminRequisitionsShow = lazy(() => import('./admin/requisitions/Show' ));

// app/views/admin/settings/availabilities/show.html.erb:8:31
const AdminSettingsAvailability = lazy(() => import('./admin/settings/Availability' ));

// app/views/admin/settings/integrations/show.html.erb:41:35
const AdminSettingsIntegrations = lazy(() => import('./admin/settings/Integrations' ));

// app/views/admin/settings/accounts/show.html.erb:43:39
const AdminSettingsAccountBasicDetails = lazy(() => import('./admin/settings/account/BasicDetails' ));

// app/views/admin/settings/accounts/show.html.erb:52:39
const AdminSettingsAccountEditPassword = lazy(() => import('./admin/settings/account/EditPassword' ));

// app/views/admin/settings/integrations/show.html.erb:39:35
const AdminSettingsIntegrationsCronofyCronofy = lazy(() => import('./admin/settings/integrations/cronofy/Cronofy' ));

// app/views/admin/settings/notifications/show.html.erb:20:31
const AdminSettingsNotificationsPreferences = lazy(() => import('./admin/settings/notifications/Preferences' ));

// app/views/admin/settings/profiles/show.html.erb:32:35
const AdminSettingsProfileCurrentProfileForm = lazy(() => import('./admin/settings/profile/CurrentProfileForm' ));

// app/views/admin/settings/securities/show.html.erb:48:43
const AdminSettingsSecurityTwoFactorAuthenticationConfiguration = lazy(() => import('./admin/settings/security/twoFactorAuthentication/Configuration' ));

// app/views/admin/settings/templates/document_templates/index.html.erb:8:29
const AdminSettingsTemplatesDocumentTemplates = lazy(() => import('./admin/settings/templates/DocumentTemplates' ));

// app/views/admin/settings/templates/email_templates/index.html.erb:8:29
// app/views/admin/settings/templates/onboarding_email_templates/index.html.erb:8:29
const AdminSettingsTemplatesEmailTemplates = lazy(() => import('./admin/settings/templates/EmailTemplates' ));

// app/views/admin/settings/templates/equality_monitoring_templates/index.html.erb:8:29
const AdminSettingsTemplatesEqualityMonitoringTemplates = lazy(() => import('./admin/settings/templates/EqualityMonitoringTemplates' ));

// app/views/admin/settings/templates/hiring_workflows/index.html.erb:8:29
const AdminSettingsTemplatesHiringWorkflows = lazy(() => import('./admin/settings/templates/HiringWorkflows' ));

// app/views/admin/settings/templates/interview_types/index.html.erb:8:29
const AdminSettingsTemplatesInterviewTypes = lazy(() => import('./admin/settings/templates/InterviewTypes' ));

// app/views/admin/settings/templates/job_groups/index.html.erb:8:29
const AdminSettingsTemplatesJobGroups = lazy(() => import('./admin/settings/templates/JobGroups' ));

// app/views/admin/settings/templates/job_requisitions/index.html.erb:8:29
const AdminSettingsTemplatesJobRequisitions = lazy(() => import('./admin/settings/templates/JobRequisitions' ));

// app/views/admin/settings/templates/job_templates/index.html.erb:8:29
const AdminSettingsTemplatesJobTemplates = lazy(() => import('./admin/settings/templates/JobTemplates' ));

// app/views/admin/settings/templates/offer_templates/index.html.erb:8:29
const AdminSettingsTemplatesOfferTemplates = lazy(() => import('./admin/settings/templates/OfferTemplates' ));

// app/views/admin/settings/templates/onboarding_questions/index.html.erb:8:29
const AdminSettingsTemplatesOnboardingQuestions = lazy(() => import('./admin/settings/templates/OnboardingQuestions' ));

// app/views/admin/settings/templates/question_bank/index.html.erb:8:29
const AdminSettingsTemplatesQuestionBank = lazy(() => import('./admin/settings/templates/QuestionBank' ));

// app/views/admin/settings/templates/question_collections/index.html.erb:8:29
const AdminSettingsTemplatesQuestionCollections = lazy(() => import('./admin/settings/templates/QuestionCollections' ));

// app/views/admin/settings/templates/scorecards/index.html.erb:8:29
const AdminSettingsTemplatesScorecards = lazy(() => import('./admin/settings/templates/Scorecards' ));

// app/views/admin/settings/templates/sign_off_workflows/index.html.erb:8:29
const AdminSettingsTemplatesSignOffWorkflows = lazy(() => import('./admin/settings/templates/SignOffWorkflows' ));

// app/views/admin/settings/templates/sms_templates/index.html.erb:8:29
const AdminSettingsTemplatesSmsTemplates = lazy(() => import('./admin/settings/templates/SmsTemplates' ));

// app/views/admin/settings/templates/structured_sections/index.html.erb:8:29
const AdminSettingsTemplatesStructuredSections = lazy(() => import('./admin/settings/templates/StructuredSections' ));

// app/views/admin/settings/templates/workflow_automations/index.html.erb:8:29
const AdminSettingsTemplatesWorkflowAutomations = lazy(() => import('./admin/settings/templates/WorkflowAutomations' ));

// app/views/admin/company/shared/_careers_site_secondary_nav.html.erb:5:29
// app/views/admin/company/shared/_settings_secondary_nav.html.erb:5:29
// app/views/platform/companies/jobs/deleted.html.erb:18:29
// app/views/platform/companies/jobs/index.html.erb:18:29
// app/views/platform/companies/requisitions/deleted.html.erb:18:29
// app/views/platform/companies/requisitions/index.html.erb:17:29
const AdminSharedBasicResponsiveSecondaryTabs = lazy(() => import('./admin/shared/BasicResponsiveSecondaryTabs' ));

// app/views/admin/company/details/edit.html.erb:11:37
// app/views/admin/data_protection_policies/edit.html.erb:19:35
const AdminSharedFormLanguageDropdown = lazy(() => import('./admin/shared/FormLanguageDropdown' ));

// app/views/admin/talent_pipelines/interviews/interview_invites/index.html.erb:9:29
const AdminSharedInterviewInvites = lazy(() => import('./admin/shared/InterviewInvites' ));

// app/views/admin/careers_sites/edit.html.erb:6:29
// app/views/admin/company/shared/_top_nav.html.erb:4:29
// app/views/admin/jobs/_overview_bar.html.erb:5:27
// app/views/admin/jobs/adverts/index.html.erb:11:29
// app/views/admin/jobs/applications/index.html.erb:11:29
// app/views/admin/jobs/applications/lanes.html.erb:11:29
// app/views/admin/jobs/applications/new.html.erb:11:29
// app/views/admin/jobs/edit.html.erb:13:31
// app/views/admin/jobs/facebook_campaigns/index.html.erb:11:29
// app/views/admin/jobs/group_interviews/candidates.html.erb:11:29
// app/views/admin/jobs/group_interviews/index.html.erb:11:29
// app/views/admin/jobs/group_interviews/sessions.html.erb:11:29
// app/views/admin/jobs/group_interviews/show.html.erb:11:29
// app/views/admin/jobs/insights/index.html.erb:11:29
// app/views/admin/jobs/integrations/cv_library_postings/edit.html.erb:11:29
// app/views/admin/jobs/integrations/cv_library_postings/new.html.erb:11:29
// app/views/admin/jobs/integrations/index.html.erb:11:29
// app/views/admin/jobs/integrations/reed_postings/edit.html.erb:11:29
// app/views/admin/jobs/integrations/reed_postings/new.html.erb:11:29
// app/views/admin/jobs/integrations/seek_postings/edit.html.erb:11:29
// app/views/admin/jobs/integrations/seek_postings/new.html.erb:11:29
// app/views/admin/jobs/integrations/total_jobs_postings/edit.html.erb:11:29
// app/views/admin/jobs/integrations/total_jobs_postings/new.html.erb:11:29
// app/views/admin/jobs/interviews/index.html.erb:11:29
// app/views/admin/jobs/job_board_postings/naukri_postings/edit.html.erb:11:29
// app/views/admin/jobs/job_board_postings/naukri_postings/new.html.erb:11:29
// app/views/admin/jobs/job_boards/index.html.erb:11:31
// app/views/admin/jobs/job_postings/index.html.erb:11:31
// app/views/admin/jobs/job_seekers/index.html.erb:11:29
// app/views/admin/jobs/linked_in_postings/index.html.erb:11:31
// app/views/admin/jobs/offer_letters/edit.html.erb:11:29
// app/views/admin/jobs/positions/index.html.erb:11:29
// app/views/admin/jobs/qrcode.html.erb:11:29
// app/views/admin/jobs/scorecard_ratings/index.html.erb:11:29
// app/views/admin/jobs/share.html.erb:11:29
// app/views/admin/jobs/sharing_links/index.html.erb:11:29
// app/views/admin/jobs/sign_off/index.html.erb:12:31
// app/views/admin/jobs/stages/_interview_header.html.erb:5:27
// app/views/admin/jobs/stages/applications/index.html.erb:11:29
// app/views/admin/jobs/stages/edit.html.erb:11:29
// app/views/admin/jobs/teams/show.html.erb:11:29
// app/views/admin/onboarding/candidates/archived.html.erb:20:29
// app/views/admin/onboarding/candidates/legacy.html.erb:20:29
// app/views/admin/onboarding/candidates/settings.html.erb:20:29
// app/views/admin/settings/notifications/show.html.erb:9:29
// app/views/admin/settings/templates/shared/_nav_wrapper.html.erb:3:27
// app/views/admin/shared/settings/_edit_user_settings_nav.html.erb:1:25
// app/views/admin/talent_pipelines/job_seekers/_header.html.erb:3:27
// app/views/admin/talent_pipelines/job_seekers/share.html.erb:9:31
// app/views/admin/talent_pipelines/job_seekers/show.html.erb:23:31
// app/views/admin/talent_pipelines/settings/show.html.erb:6:29
const AdminSharedPrimaryTabs = lazy(() => import('./admin/shared/PrimaryTabs' ));

// app/views/admin/jobs/stages/_tabbed_nav.html.erb:1:25
// app/views/admin/talent_pipelines/interviews/_tabbed_nav.html.erb:1:25
// app/views/admin/talent_pipelines/job_seekers/scorecard_ratings.html.erb:8:29
// app/views/admin/talent_pipelines/job_seekers/scorecards.html.erb:7:29
const AdminSharedSecondaryLinkTabs = lazy(() => import('./admin/shared/SecondaryLinkTabs' ));

// app/views/admin/talent_pipelines/job_seekers/show.html.erb:25:31
const AdminSharedCandidatesActionsSendToQueueQueueActions = lazy(() => import('./admin/shared/candidates/actions/sendToQueue/QueueActions' ));

// app/views/layouts/fullscreen/_header.html.erb:12:35
const AdminSharedTasksIconIndicator = lazy(() => import('./admin/shared/tasks/IconIndicator' ));

// app/views/admin/insights/equality_monitorings/show.html.erb:35:31
const AdminSharedWidgetsEqualityMonitoringCategories = lazy(() => import('./admin/shared/widgets/EqualityMonitoringCategories' ));

// app/views/admin/insights/candidate_surveys/show.html.erb:75:35
const AdminSharedWidgetsCandidateSurveysCandidateExperienceScore = lazy(() => import('./admin/shared/widgets/candidateSurveys/CandidateExperienceScore' ));

// app/views/admin/insights/candidate_surveys/show.html.erb:87:35
const AdminSharedWidgetsCandidateSurveysCandidateExperienceScoreOverTime = lazy(() => import('./admin/shared/widgets/candidateSurveys/CandidateExperienceScoreOverTime' ));

// app/views/admin/insights/candidate_surveys/show.html.erb:103:31
const AdminSharedWidgetsCandidateSurveysComments = lazy(() => import('./admin/shared/widgets/candidateSurveys/Comments' ));

// app/views/admin/insights/candidate_surveys/show.html.erb:109:31
const AdminSharedWidgetsCandidateSurveysComparison = lazy(() => import('./admin/shared/widgets/candidateSurveys/Comparison' ));

// app/views/admin/insights/candidate_surveys/show.html.erb:51:35
const AdminSharedWidgetsCandidateSurveysMetricsSurveyResponseRate = lazy(() => import('./admin/shared/widgets/candidateSurveys/metrics/SurveyResponseRate' ));

// app/views/admin/insights/candidate_surveys/show.html.erb:63:35
const AdminSharedWidgetsCandidateSurveysMetricsTotalResponses = lazy(() => import('./admin/shared/widgets/candidateSurveys/metrics/TotalResponses' ));

// app/views/admin/insights/candidates/show.html.erb:105:35
// app/views/admin/jobs/insights/index.html.erb:61:40
const AdminSharedWidgetsCandidatesApplicantTagBreakdown = lazy(() => import('./admin/shared/widgets/candidates/ApplicantTagBreakdown' ));

// app/views/admin/insights/candidates/show.html.erb:53:35
// app/views/admin/jobs/insights/index.html.erb:62:40
const AdminSharedWidgetsCandidatesApplicantsByChannel = lazy(() => import('./admin/shared/widgets/candidates/ApplicantsByChannel' ));

// app/views/admin/insights/candidates/show.html.erb:143:35
// app/views/admin/jobs/insights/index.html.erb:60:40
const AdminSharedWidgetsCandidatesApplicationsByDateCreated = lazy(() => import('./admin/shared/widgets/candidates/ApplicationsByDateCreated' ));

// app/views/admin/insights/candidates/show.html.erb:40:35
// app/views/admin/insights/candidates/show.html.erb:155:36
// app/views/admin/insights/candidates/show.html.erb:167:36
// app/views/admin/insights/candidates/show.html.erb:179:36
// app/views/admin/insights/candidates/show.html.erb:191:36
// app/views/admin/insights/candidates/show.html.erb:203:36
const AdminSharedWidgetsCandidatesApplicationsByStage = lazy(() => import('./admin/shared/widgets/candidates/ApplicationsByStage' ));

// app/views/admin/insights/candidates/show.html.erb:65:35
// app/views/admin/jobs/insights/index.html.erb:63:40
const AdminSharedWidgetsCandidatesHiredByChannel = lazy(() => import('./admin/shared/widgets/candidates/HiredByChannel' ));

// app/views/admin/jobs/insights/index.html.erb:46:39
const AdminSharedWidgetsCandidatesOutboundEmailDistribution = lazy(() => import('./admin/shared/widgets/candidates/OutboundEmailDistribution' ));

// app/views/admin/insights/candidates/show.html.erb:77:35
const AdminSharedWidgetsCandidatesRecruiterApplicationsBySubmission = lazy(() => import('./admin/shared/widgets/candidates/RecruiterApplicationsBySubmission' ));

// app/views/admin/insights/candidates/show.html.erb:167:36
const AdminSharedWidgetsCandidatesMetricsAverageCountPerJob = lazy(() => import('./admin/shared/widgets/candidates/metrics/AverageCountPerJob' ));

// app/views/admin/insights/candidates/show.html.erb:191:36
const AdminSharedWidgetsCandidatesMetricsAverageCountPerJobWithHire = lazy(() => import('./admin/shared/widgets/candidates/metrics/AverageCountPerJobWithHire' ));

// app/views/admin/insights/candidates/show.html.erb:203:36
// app/views/admin/jobs/insights/index.html.erb:66:40
const AdminSharedWidgetsCandidatesMetricsPercentHired = lazy(() => import('./admin/shared/widgets/candidates/metrics/PercentHired' ));

// app/views/admin/insights/users/show.html.erb:25:33
const AdminSharedWidgetsCommentsByUser = lazy(() => import('./admin/shared/widgets/comments/ByUser' ));

// app/views/admin/insights/interviews/show.html.erb:37:33
const AdminSharedWidgetsInterviewsByInterviewTypeInterviewType = lazy(() => import('./admin/shared/widgets/interviews/ByInterviewTypeInterviewType' ));

// app/views/admin/insights/interviews/show.html.erb:47:33
const AdminSharedWidgetsInterviewsByInterviewTypeName = lazy(() => import('./admin/shared/widgets/interviews/ByInterviewTypeName' ));

// app/views/admin/insights/interviews/show.html.erb:81:33
const AdminSharedWidgetsInterviewsByTimePeriod = lazy(() => import('./admin/shared/widgets/interviews/ByTimePeriod' ));

// app/views/admin/insights/candidates/show.html.erb:89:35
const AdminSharedWidgetsJobSeekersTalentPipelineByChannel = lazy(() => import('./admin/shared/widgets/jobSeekers/TalentPipelineByChannel' ));

// app/views/admin/insights/candidates/show.html.erb:155:36
const AdminSharedWidgetsJobSeekersTalentPipelineByDateCreated = lazy(() => import('./admin/shared/widgets/jobSeekers/TalentPipelineByDateCreated' ));

// app/views/admin/insights/candidates/show.html.erb:118:35
const AdminSharedWidgetsJobSeekersTalentPipelineTagBreakdown = lazy(() => import('./admin/shared/widgets/jobSeekers/TalentPipelineTagBreakdown' ));

// app/views/admin/jobs/insights/index.html.erb:39:41
const AdminSharedWidgetsJobsApplicationsByJobPosting = lazy(() => import('./admin/shared/widgets/jobs/ApplicationsByJobPosting' ));

// app/views/admin/jobs/insights/index.html.erb:36:39
const AdminSharedWidgetsJobsApplicationsByJobStage = lazy(() => import('./admin/shared/widgets/jobs/ApplicationsByJobStage' ));

// app/views/admin/jobs/insights/index.html.erb:37:39
const AdminSharedWidgetsJobsAverageTimeSpentInStages = lazy(() => import('./admin/shared/widgets/jobs/AverageTimeSpentInStages' ));

// app/views/admin/insights/jobs/show.html.erb:42:33
const AdminSharedWidgetsJobsAverageTimeToFill = lazy(() => import('./admin/shared/widgets/jobs/AverageTimeToFill' ));

// app/views/admin/insights/jobs/show.html.erb:54:33
const AdminSharedWidgetsJobsAverageTimeToHire = lazy(() => import('./admin/shared/widgets/jobs/AverageTimeToHire' ));

// app/views/admin/insights/jobs/show.html.erb:38:33
const AdminSharedWidgetsJobsByStatus = lazy(() => import('./admin/shared/widgets/jobs/ByStatus' ));

// app/views/admin/jobs/insights/index.html.erb:43:41
const AdminSharedWidgetsJobsFurthestStageReached = lazy(() => import('./admin/shared/widgets/jobs/FurthestStageReached' ));

// app/views/admin/insights/jobs/show.html.erb:62:33
const AdminSharedWidgetsJobsJobOpenDuration = lazy(() => import('./admin/shared/widgets/jobs/JobOpenDuration' ));

// app/views/admin/insights/candidates/show.html.erb:131:35
// app/views/admin/jobs/insights/index.html.erb:47:39
const AdminSharedWidgetsJobsNumberOfApplicants = lazy(() => import('./admin/shared/widgets/jobs/NumberOfApplicants' ));

// app/views/admin/jobs/insights/index.html.erb:48:39
const AdminSharedWidgetsJobsNumberOfApplications = lazy(() => import('./admin/shared/widgets/jobs/NumberOfApplications' ));

// app/views/admin/insights/jobs/show.html.erb:46:33
const AdminSharedWidgetsJobsTimeToFill = lazy(() => import('./admin/shared/widgets/jobs/TimeToFill' ));

// app/views/admin/insights/jobs/show.html.erb:58:33
const AdminSharedWidgetsJobsTimeToHire = lazy(() => import('./admin/shared/widgets/jobs/TimeToHire' ));

// app/views/admin/insights/interviews/show.html.erb:61:33
const AdminSharedWidgetsKeyMetricsAverageInterviewTeamMembersByStage = lazy(() => import('./admin/shared/widgets/keyMetrics/AverageInterviewTeamMembersByStage' ));

// app/views/admin/insights/candidates/show.html.erb:179:36
// app/views/admin/jobs/insights/index.html.erb:49:39
const AdminSharedWidgetsKeyMetricsAverageMessagesPerApplicant = lazy(() => import('./admin/shared/widgets/keyMetrics/AverageMessagesPerApplicant' ));

// app/views/admin/jobs/insights/index.html.erb:65:40
const AdminSharedWidgetsKeyMetricsAverageTimeBeforeFirstCandidateCommunication = lazy(() => import('./admin/shared/widgets/keyMetrics/AverageTimeBeforeFirstCandidateCommunication' ));

// app/views/admin/jobs/insights/index.html.erb:64:40
const AdminSharedWidgetsKeyMetricsAverageTimeBetweenCandidateCommunication = lazy(() => import('./admin/shared/widgets/keyMetrics/AverageTimeBetweenCandidateCommunication' ));

// app/views/admin/jobs/insights/index.html.erb:50:40
const AdminSharedWidgetsKeyMetricsCandidateMessageCountDistribution = lazy(() => import('./admin/shared/widgets/keyMetrics/CandidateMessageCountDistribution' ));

// app/views/admin/insights/interviews/show.html.erb:71:33
const AdminSharedWidgetsKeyMetricsPercentageIntervieweesHired = lazy(() => import('./admin/shared/widgets/keyMetrics/PercentageIntervieweesHired' ));

// app/views/admin/insights/users/show.html.erb:52:33
const AdminSharedWidgetsMessagesByUser = lazy(() => import('./admin/shared/widgets/messages/ByUser' ));

// app/views/admin/insights/users/show.html.erb:44:33
const AdminSharedWidgetsUsersSignIns = lazy(() => import('./admin/shared/widgets/users/SignIns' ));

// app/views/admin/tags/index.html.erb:4:25
const AdminTagManagementTagManagement = lazy(() => import('./admin/tagManagement/TagManagement' ));

// app/views/admin/talent_pipelines/job_seekers/new.html.erb:9:31
const AdminTalentPipelineJobSeekerForm = lazy(() => import('./admin/talentPipeline/JobSeekerForm' ));

// app/views/admin/talent_pipelines/job_seekers/index.html.erb:16:31
const AdminTalentPipelinePools = lazy(() => import('./admin/talentPipeline/Pools' ));

// app/views/admin/talent_pipelines/job_seekers/scorecards.html.erb:25:35
const AdminTalentPipelineScorecardableScorecards = lazy(() => import('./admin/talentPipeline/ScorecardableScorecards' ));

// app/views/admin/talent_pipelines/interviews/interview_openings/index.html.erb:13:33
const AdminTalentPipelineInterviewOpeningsCalendar = lazy(() => import('./admin/talentPipeline/interviewOpenings/Calendar' ));

// app/views/admin/talent_pipelines/settings/show.html.erb:7:29
const AdminTalentPipelineSettingsForm = lazy(() => import('./admin/talentPipeline/settings/Form' ));

// app/views/admin/talent_pipelines/interviews/configurations/show.html.erb:10:29
const AdminTalentPipelineSettingsInterviewsConfiguration = lazy(() => import('./admin/talentPipeline/settings/interviews/Configuration' ));

// app/views/admin/user_management/access_groups/index.html.erb:7:27
const AdminUserManagementAccessGroups = lazy(() => import('./admin/userManagement/AccessGroups' ));

// app/views/admin/user_management/agencies/index.html.erb:7:27
const AdminUserManagementAgencies = lazy(() => import('./admin/userManagement/Agencies' ));

// app/views/admin/user_management/agency_users/index.html.erb:7:27
const AdminUserManagementAgencyUsers = lazy(() => import('./admin/userManagement/AgencyUsers' ));

// app/views/admin/user_management/approval_lists/index.html.erb:6:27
const AdminUserManagementApprovalLists = lazy(() => import('./admin/userManagement/ApprovalLists' ));

// app/views/admin/user_management/company_teams/index.html.erb:4:25
const AdminUserManagementCompanyTeams = lazy(() => import('./admin/userManagement/CompanyTeams' ));

// app/views/admin/user_management/non_user_employees/index.html.erb:7:27
const AdminUserManagementNonUserEmployees = lazy(() => import('./admin/userManagement/NonUserEmployees' ));

// app/views/admin/user_management/recruiters/index.html.erb:7:27
const AdminUserManagementRecruiters = lazy(() => import('./admin/userManagement/Recruiters' ));

// app/views/admin/user_management/employees/index.html.erb:7:27
const AdminUserManagementUsers = lazy(() => import('./admin/userManagement/Users' ));

// app/views/admin/user_management/employees/new.html.erb:14:27
// app/views/admin/user_management/recruiters/new.html.erb:10:27
const AdminUserManagementEmployeesInvite = lazy(() => import('./admin/userManagement/employees/Invite' ));

// app/views/admin/webhooks/endpoints/show.html.erb:12:31
const AdminWebhooksEndpoint = lazy(() => import('./admin/webhooks/Endpoint' ));

// app/views/admin/webhooks/index.html.erb:13:31
const AdminWebhooksEndpoints = lazy(() => import('./admin/webhooks/Endpoints' ));

// app/views/companies/index.html.erb:18:29
// app/views/integrations/providers/rippling/installs/show.html.erb:14:29
const CompaniesEmpty = lazy(() => import('./companies/Empty' ));

// app/views/shared/companies/fieldsets/_languages.html.erb:19:31
const CompaniesLanguagesField = lazy(() => import('./companies/LanguagesField' ));

// app/views/admin/company/securities/show.html.erb:23:41
const CompaniesSecurity = lazy(() => import('./companies/Security' ));

// app/views/shared/companies/fieldsets/_info.html.erb:176:35
const CompaniesWebsiteInput = lazy(() => import('./companies/WebsiteInput' ));

// app/views/auth/azure_oauth2/missing_user.html.erb:20:33
// app/views/auth/google_oauth2/missing_user.html.erb:20:33
const DeviseBackToLogin = lazy(() => import('./devise/BackToLogin' ));

// app/views/devise/sessions/logged_out.html.erb:3:25
const DeviseLoggedOut = lazy(() => import('./devise/LoggedOut' ));

// app/views/devise/sessions/new.html.erb:5:25
// app/views/platform/admins/sessions/new.html.erb:3:25
const DeviseLogin = lazy(() => import('./devise/Login' ));

// app/views/devise/passwords/new.html.erb:3:25
const DevisePasswordReset = lazy(() => import('./devise/PasswordReset' ));

// app/views/devise/invitations/edit.html.erb:9:29
// app/views/devise/passwords/edit.html.erb:5:27
const DeviseSetPassword = lazy(() => import('./devise/SetPassword' ));

// app/views/devise/sessions/two_factor.html.erb:8:29
const DeviseTwoFactor = lazy(() => import('./devise/TwoFactor' ));

// app/views/extensions/chrome/users/sessions/new.html.erb:21:27
const DeviseExtensionsChromeLogin = lazy(() => import('./devise/extensions/chrome/Login' ));

// app/views/extensions/chrome/users/sessions/two_factor.html.erb:8:29
const DeviseExtensionsChromeTwoFactor = lazy(() => import('./devise/extensions/chrome/TwoFactor' ));

// app/views/extensions/chrome/admin/sourcings/show.html.erb:20:27
const ExtensionsChromeSourcing = lazy(() => import('./extensions/chrome/Sourcing' ));

// app/views/external/shared/blocks/_careers_hero_block.html.erb:87:47
// app/views/external/shared/blocks/_careers_hero_block.html.erb:116:47
// app/views/external/shared/blocks/_careers_jobs_block.html.erb:46:39
// app/views/external/shared/blocks/_careers_jobs_filter_block.html.erb:28:37
const ExternalFilters = lazy(() => import('./external/Filters' ));

// app/views/external/shared/blocks/_careers_jobs_block.html.erb:71:27
const ExternalJobs = lazy(() => import('./external/Jobs' ));

// app/views/external/approval_workflow_stages_users/approve.html.erb:42:47
const ExternalApprovalsAssignOrSkip = lazy(() => import('./external/approvals/AssignOrSkip' ));

// app/views/external/shared/blocks/_careers_cards_block.html.erb:23:33
// app/views/external/shared/blocks/_careers_carousel_block.html.erb:22:31
const ExternalCareersPageCarousel = lazy(() => import('./external/careersPage/Carousel' ));

// app/views/external/shared/blocks/_careers_image_gallery_block.html.erb:7:31
const ExternalCareersPageImageGallery = lazy(() => import('./external/careersPage/ImageGallery' ));

// app/views/external/shared/blocks/_careers_hero_block.html.erb:103:47
// app/views/external/shared/blocks/_careers_hero_block.html.erb:132:47
// app/views/external/shared/blocks/_careers_jobs_filter_block.html.erb:42:37
const ExternalCareersPageJobsFilter = lazy(() => import('./external/careersPage/JobsFilter' ));

// app/views/external/job_postings/show/_rich_text.html.erb:1:25
const ExternalCareersPageRenderRichTrixContent = lazy(() => import('./external/careersPage/RenderRichTrixContent' ));

// app/views/external/shared/blocks/navigation/_hero.html.erb:3:27
// app/views/external/shared/blocks/navigation/_justified.html.erb:21:29
const ExternalCareersPageResponsiveNavigation = lazy(() => import('./external/careersPage/ResponsiveNavigation' ));

// app/views/external/shared/blocks/_careers_card_block.html.erb:27:39
// app/views/external/shared/blocks/_careers_card_block.html.erb:69:41
// app/views/external/shared/blocks/_careers_card_block.html.erb:135:37
// app/views/external/shared/blocks/_careers_video_block.html.erb:5:29
const ExternalCareersPageVideo = lazy(() => import('./external/careersPage/Video' ));

// app/views/external/shared/blocks/_careers_hero_block.html.erb:6:29
const ExternalCareersPageVideoHero = lazy(() => import('./external/careersPage/VideoHero' ));

// app/views/external/document_requests/_pending.html.erb:27:41
const ExternalDocumentRequestsSign = lazy(() => import('./external/document_requests/Sign' ));

// app/views/external/group_assessment_attendees/show.html.erb:129:35
const ExternalGroupAssessmentsReschedulingOptions = lazy(() => import('./external/groupAssessments/ReschedulingOptions' ));

// app/views/external/interviews/new.html.erb:82:35
// app/views/external/talent_pipelines/interviews/new.html.erb:44:33
const ExternalInterviewsSchedule = lazy(() => import('./external/interviews/Schedule' ));

// app/views/external/group_assessment_attendees/new.html.erb:19:33
// app/views/external/group_interview_attendees/new.html.erb:19:33
// app/views/external/interviews/new.html.erb:19:33
// app/views/external/talent_pipelines/interviews/new.html.erb:12:33
const ExternalInterviewsTimeZone = lazy(() => import('./external/interviews/TimeZone' ));

// app/views/external/group_assessment_attendees/new.html.erb:77:35
// app/views/external/group_interview_attendees/new.html.erb:76:35
const ExternalInterviewsGroupSchedule = lazy(() => import('./external/interviews/group/Schedule' ));

// app/views/external/job_postings/show/_attached_documents.html.erb:10:31
const ExternalJobsDocumentCard = lazy(() => import('./external/jobs/DocumentCard' ));

// app/views/external/jobs/hiring_process/index.html.erb:89:29
const ExternalJobsHiringProcessSidebar = lazy(() => import('./external/jobs/HiringProcessSidebar' ));

// app/views/external/job_postings/show/_stages_carousel.html.erb:5:29
const ExternalJobsStagesCarousel = lazy(() => import('./external/jobs/StagesCarousel' ));

// app/views/external/job_postings/applications/fieldsets/_submit.html.erb:120:35
const ExternalJobsApplicationsNew = lazy(() => import('./external/jobs/applications/New' ));

// app/views/external/equality_monitorings/surveys/new.html.erb:59:47
// app/views/external/job_postings/applications/fieldsets/_submit.html.erb:26:33
// app/views/external/shared/equality_monitoring/_fields.html.erb:34:39
const ExternalJobsEqualityMonitoringTsSelect = lazy(() => import('./external/jobs/equalityMonitoring/TsSelect' ));

// app/views/external/offers/_pending.html.erb:80:37
const ExternalOffersSign = lazy(() => import('./external/offers/Sign' ));

// app/views/integrations/providers/rippling/installs/show.html.erb:6:29
const IntegrationsProvidersRipplingInstall = lazy(() => import('./integrations/providers/rippling/Install' ));

// app/views/mydata/static_pages/index.html.erb:72:31
const MydataRegisteredInterest = lazy(() => import('./mydata/RegisteredInterest' ));

// app/views/mydata/static_pages/index.html.erb:29:31
const MydataSubmittedApplications = lazy(() => import('./mydata/SubmittedApplications' ));

// app/views/onboarding/candidate_responses/show.html.erb:46:31
const OnboardingCandidateResponse = lazy(() => import('./onboarding/CandidateResponse' ));

// app/views/onboarding/processes/show.html.erb:55:29
const OnboardingProcess = lazy(() => import('./onboarding/Process' ));

// app/views/onboarding/team_member_actions/show.html.erb:48:29
const OnboardingTeamMemberAction = lazy(() => import('./onboarding/TeamMemberAction' ));

// app/views/onboarding/team_member_forms/show.html.erb:46:31
const OnboardingTeamMemberForm = lazy(() => import('./onboarding/TeamMemberForm' ));

// app/views/onboarding/workflows/show.html.erb:49:29
const OnboardingWorkflow = lazy(() => import('./onboarding/Workflow' ));

// app/views/onboarding/candidate_responses/show.html.erb:31:35
// app/views/onboarding/processes/show.html.erb:39:37
// app/views/onboarding/team_member_actions/show.html.erb:31:35
// app/views/onboarding/team_member_forms/show.html.erb:31:35
const OnboardingProcessCandidateHeaderInfo = lazy(() => import('./onboarding/process/CandidateHeaderInfo' ));

// app/views/onboarding/processes/show.html.erb:14:37
const OnboardingProcessMobileCandidateHeaderInfo = lazy(() => import('./onboarding/process/MobileCandidateHeaderInfo' ));

// app/views/platform/ai_assistant_conversations/index.html.erb:5:27
const PlatformAiAssistantConversations = lazy(() => import('./platform/AiAssistantConversations' ));

// app/views/platform/inbound_emails/index.html.erb:5:27
const PlatformInboundEmails = lazy(() => import('./platform/InboundEmails' ));

// app/views/platform/limited_access_grants/show.html.erb:6:27
const PlatformLimitedAccessGrants = lazy(() => import('./platform/LimitedAccessGrants' ));

// app/views/platform/referrals_users/index.html.erb:5:27
const PlatformReferralsUsers = lazy(() => import('./platform/ReferralsUsers' ));

// app/views/platform/facebook/targeting_templates/_form.html.erb:20:27
const PlatformTargetingTemplate = lazy(() => import('./platform/TargetingTemplate' ));

// app/views/platform/adverts/job_boards/index.html.erb:5:27
const PlatformAdvertsJobBoards = lazy(() => import('./platform/adverts/JobBoards' ));

// app/views/platform/companies/workflow_automations/index.html.erb:13:29
const PlatformCompaniesAutomations = lazy(() => import('./platform/companies/Automations' ));

// app/views/platform/companies/careers_sites/index.html.erb:13:29
// app/views/platform/companies/onboarding_portals/index.html.erb:13:29
const PlatformCompaniesCareersSites = lazy(() => import('./platform/companies/CareersSites' ));

// app/views/platform/companies/_overview_bar.html.erb:9:33
const PlatformCompaniesCompanyLinksMenu = lazy(() => import('./platform/companies/CompanyLinksMenu' ));

// app/views/platform/companies/jobs/deleted.html.erb:31:33
const PlatformCompaniesDeletedJobs = lazy(() => import('./platform/companies/DeletedJobs' ));

// app/views/platform/companies/_overview_bar.html.erb:26:37
const PlatformCompaniesDemoToggle = lazy(() => import('./platform/companies/DemoToggle' ));

// app/views/platform/companies/_overview_bar.html.erb:32:37
const PlatformCompaniesGrantCareersSiteAccess = lazy(() => import('./platform/companies/GrantCareersSiteAccess' ));

// app/views/platform/companies/index.html.erb:8:25
const PlatformCompaniesIndex = lazy(() => import('./platform/companies/Index' ));

// app/views/platform/companies/linked_in_postings/index.html.erb:8:29
const PlatformCompaniesLinkedInPostings = lazy(() => import('./platform/companies/LinkedInPostings' ));

// app/views/platform/companies/onboarding_applications/index.html.erb:13:29
const PlatformCompaniesOnboardingApplications = lazy(() => import('./platform/companies/OnboardingApplications' ));

// app/views/platform/companies/outbound_requests/index.html.erb:13:29
const PlatformCompaniesOutboundRequests = lazy(() => import('./platform/companies/OutboundRequests' ));

// app/views/platform/companies/packages/show.html.erb:14:29
const PlatformCompaniesPackages = lazy(() => import('./platform/companies/Packages' ));

// app/views/platform/companies/careers_sites/index.html.erb:7:29
// app/views/platform/companies/careers_sites/show.html.erb:7:29
// app/views/platform/companies/jobs/deleted.html.erb:6:29
// app/views/platform/companies/jobs/index.html.erb:7:29
// app/views/platform/companies/linked_in_postings/index.html.erb:7:29
// app/views/platform/companies/onboarding_applications/index.html.erb:7:29
// app/views/platform/companies/onboarding_portals/index.html.erb:7:29
// app/views/platform/companies/onboarding_portals/show.html.erb:7:29
// app/views/platform/companies/outbound_requests/index.html.erb:7:29
// app/views/platform/companies/packages/show.html.erb:7:29
// app/views/platform/companies/reports/jobs/index.html.erb:7:29
// app/views/platform/companies/reports/jobs/show.html.erb:7:29
// app/views/platform/companies/requisitions/deleted.html.erb:6:29
// app/views/platform/companies/requisitions/index.html.erb:6:29
// app/views/platform/companies/seeding/index.html.erb:11:31
// app/views/platform/companies/settings/index.html.erb:7:29
// app/views/platform/companies/show.html.erb:9:29
// app/views/platform/companies/stages/index.html.erb:7:29
// app/views/platform/companies/templates/interview_types/index.html.erb:7:29
// app/views/platform/companies/templates/report_templates/index.html.erb:7:29
// app/views/platform/companies/users/index.html.erb:7:29
// app/views/platform/companies/users/show.html.erb:7:29
// app/views/platform/companies/video_interviews/index.html.erb:7:29
// app/views/platform/companies/vonq_campaigns/index.html.erb:7:29
// app/views/platform/companies/webhook_endpoints/index.html.erb:7:29
// app/views/platform/companies/workflow_automations/index.html.erb:7:29
const PlatformCompaniesPrimaryTabs = lazy(() => import('./platform/companies/PrimaryTabs' ));

// app/views/platform/companies/requisitions/deleted.html.erb:38:33
// app/views/platform/companies/requisitions/index.html.erb:37:33
const PlatformCompaniesRequisitions = lazy(() => import('./platform/companies/Requisitions' ));

// app/views/platform/companies/settings/index.html.erb:14:29
const PlatformCompaniesSettings = lazy(() => import('./platform/companies/Settings' ));

// app/views/platform/companies/stages/index.html.erb:8:29
const PlatformCompaniesStages = lazy(() => import('./platform/companies/Stages' ));

// app/views/platform/companies/users/index.html.erb:8:29
const PlatformCompaniesUsers = lazy(() => import('./platform/companies/Users' ));

// app/views/platform/companies/video_interviews/index.html.erb:13:29
const PlatformCompaniesVideoInterviews = lazy(() => import('./platform/companies/VideoInterviews' ));

// app/views/platform/companies/vonq_campaigns/index.html.erb:8:29
const PlatformCompaniesVonqCampaigns = lazy(() => import('./platform/companies/VonqCampaigns' ));

// app/views/platform/companies/webhook_endpoints/index.html.erb:13:29
const PlatformCompaniesWebhookEndpoints = lazy(() => import('./platform/companies/WebhookEndpoints' ));

// app/views/platform/companies/careers_sites/show.html.erb:8:29
// app/views/platform/companies/onboarding_portals/show.html.erb:8:29
const PlatformCompaniesCareersSitesShow = lazy(() => import('./platform/companies/careersSites/Show' ));

// app/views/platform/companies/_form.html.erb:1:25
const PlatformCompaniesFormsCompanyForm = lazy(() => import('./platform/companies/forms/CompanyForm' ));

// app/views/platform/companies/reports/jobs/show.html.erb:13:29
const PlatformCompaniesReportsJob = lazy(() => import('./platform/companies/reports/Job' ));

// app/views/platform/companies/reports/jobs/index.html.erb:13:29
const PlatformCompaniesReportsJobs = lazy(() => import('./platform/companies/reports/Jobs' ));

// app/views/platform/companies/requisitions/deleted.html.erb:31:33
// app/views/platform/companies/requisitions/index.html.erb:30:33
const PlatformCompaniesRequisitionsActions = lazy(() => import('./platform/companies/requisitions/Actions' ));

// app/views/platform/companies/seeding/index.html.erb:394:37
const PlatformCompaniesSeedingEqualityMonitoringTraits = lazy(() => import('./platform/companies/seeding/EqualityMonitoringTraits' ));

// app/views/platform/companies/show.html.erb:79:39
const PlatformCompaniesShowEditableBillingFields = lazy(() => import('./platform/companies/show/EditableBillingFields' ));

// app/views/platform/companies/show.html.erb:69:41
const PlatformCompaniesShowEditableCountry = lazy(() => import('./platform/companies/show/EditableCountry' ));

// app/views/platform/companies/show.html.erb:46:41
const PlatformCompaniesShowEditableCurrency = lazy(() => import('./platform/companies/show/EditableCurrency' ));

// app/views/platform/companies/show.html.erb:51:41
const PlatformCompaniesShowEditableTimeZone = lazy(() => import('./platform/companies/show/EditableTimeZone' ));

// app/views/platform/companies/templates/interview_types/index.html.erb:13:29
const PlatformCompaniesTemplatesInterviewTypes = lazy(() => import('./platform/companies/templates/InterviewTypes' ));

// app/views/platform/companies/templates/report_templates/index.html.erb:13:29
const PlatformCompaniesTemplatesReportTemplates = lazy(() => import('./platform/companies/templates/ReportTemplates' ));

// app/views/platform/companies/users/show.html.erb:13:29
const PlatformCompaniesUsersShow = lazy(() => import('./platform/companies/users/Show' ));

// app/views/platform/database_infos/show.html.erb:4:25
const PlatformDatabaseInfoShow = lazy(() => import('./platform/databaseInfo/Show' ));

// app/views/platform/deals/index.html.erb:4:25
const PlatformDealsIndex = lazy(() => import('./platform/deals/Index' ));

// app/views/platform/delayed_jobs/index.html.erb:4:25
const PlatformDelayedJobsIndex = lazy(() => import('./platform/delayedJobs/Index' ));

// app/views/platform/insights/twilio_integrations/index.erb:5:27
const PlatformInsightsTwilioIntegrations = lazy(() => import('./platform/insights/TwilioIntegrations' ));

// app/views/platform/integration_descriptions/index.html.erb:5:27
const PlatformIntegrationDescriptionIndex = lazy(() => import('./platform/integrationDescription/Index' ));

// app/views/platform/companies/job_seekers/index.html.erb:32:38
// app/views/platform/companies/seeding/index.html.erb:103:42
const PlatformJobSeekersCreateDemoJobSeekers = lazy(() => import('./platform/jobSeekers/CreateDemoJobSeekers' ));

// app/views/platform/companies/jobs/index.html.erb:31:33
const PlatformJobsCompaniesActions = lazy(() => import('./platform/jobs/companies/Actions' ));

// app/views/platform/companies/jobs/index.html.erb:42:33
const PlatformJobsCompaniesJobs = lazy(() => import('./platform/jobs/companies/Jobs' ));

// app/views/layouts/platform/_desktop_nav.html.erb:18:33
const PlatformLayoutCompanySelector = lazy(() => import('./platform/layout/CompanySelector' ));

// app/views/platform/platform_admins/index.html.erb:5:27
const PlatformPlatformAdminsIndex = lazy(() => import('./platform/platformAdmins/Index' ));

// app/views/platform/security/two_factor_recovery_requests/index.html.erb:64:47
const PlatformSecurityTwoFactorAuthenticationReset = lazy(() => import('./platform/security/twoFactorAuthentication/Reset' ));

// app/views/platform/settings/securities/show.html.erb:24:41
const PlatformSettingsSecurityTwoFactorAuthenticationConfiguration = lazy(() => import('./platform/settings/security/twoFactorAuthentication/Configuration' ));

// app/views/referrals/referral_jobs/index.html.erb:4:25
const ReferralsAvailableRolesAvailableRoles = lazy(() => import('./referrals/availableRoles/AvailableRoles' ));

// app/views/referrals/jobs/index.html.erb:4:25
const ReferralsJobsIndex = lazy(() => import('./referrals/jobs/Index' ));

// app/views/layouts/referrals.html.erb:36:27
const ReferralsLayoutMobileNavigation = lazy(() => import('./referrals/layout/MobileNavigation' ));

// app/views/referrals/referrals/index.html.erb:4:25
const ReferralsMyReferralsMyReferrals = lazy(() => import('./referrals/myReferrals/MyReferrals' ));

// app/views/referrals/settings/show.html.erb:4:25
const ReferralsSettingsSettings = lazy(() => import('./referrals/settings/Settings' ));

// app/views/referrals_users/invitations/edit.html.erb:4:27
const ReferralsUsersInvitationsSetPassword = lazy(() => import('./referrals/users/invitations/SetPassword' ));

// app/views/referrals/users/registrations/new.html.erb:5:27
const ReferralsUsersRegistrationsSignUp = lazy(() => import('./referrals/users/registrations/SignUp' ));

// app/views/layouts/admin/_desktop_nav.html.erb:6:31
// app/views/layouts/platform/_desktop_nav.html.erb:6:31
// app/views/layouts/referrals/_desktop_nav.html.erb:6:31
const SharedBreadcrumbs = lazy(() => import('./shared/Breadcrumbs' ));

// app/views/layouts/application.html.erb:30:29
// app/views/layouts/fullscreen.html.erb:25:27
const SharedIntercomEventTracking = lazy(() => import('./shared/IntercomEventTracking' ));

// app/views/external/job_postings/show.html.erb:37:25
const SharedMobileActionsBar = lazy(() => import('./shared/MobileActionsBar' ));

// app/views/external/shared/blocks/_careers_jobs_block.html.erb:33:39
const SharedReactSelectFilters = lazy(() => import('./shared/ReactSelectFilters' ));

// app/views/external/shared/blocks/_careers_hero_block.html.erb:74:47
// app/views/external/shared/blocks/_careers_jobs_block.html.erb:18:39
// app/views/external/shared/blocks/_careers_jobs_filter_block.html.erb:15:37
const SharedSearch = lazy(() => import('./shared/Search' ));

// app/views/shared/companies/fieldsets/_info.html.erb:151:35
// app/views/shared/companies/fieldsets/_info.html.erb:187:35
// app/views/shared/companies/fieldsets/_info.html.erb:206:35
const SharedThumbtackSelect = lazy(() => import('./shared/ThumbtackSelect' ));

// app/views/platform/facebook/adverts/_targeting_template_form.html.erb:7:31
const SharedTsSelect = lazy(() => import('./shared/TsSelect' ));

// app/views/external/job_postings/applications/fieldsets/_details.html.erb:115:31
// app/views/external/job_seekers/fieldsets/_personal_details.html.erb:92:31
const SharedFormAddress = lazy(() => import('./shared/form/Address' ));

// app/views/external/job_postings/applications/fieldsets/_profile.html.erb:32:35
// app/views/external/job_postings/applications/fieldsets/_profile.html.erb:75:37
// app/views/external/job_seekers/fieldsets/_resume.html.erb:38:35
// app/views/external/surveys/_form.html.erb:181:39
// app/views/platform/companies/job_seekers/fieldsets/_resume.html.erb:13:31
// app/views/platform/companies/seeding/job_seekers/fieldsets/_resume.html.erb:13:31
const SharedFormFileField = lazy(() => import('./shared/form/FileField' ));

// app/views/shared/companies/fieldsets/_social.html.erb:18:31
// app/views/shared/companies/fieldsets/_social.html.erb:34:31
// app/views/shared/companies/fieldsets/_social.html.erb:50:31
// app/views/shared/companies/fieldsets/_social.html.erb:66:31
// app/views/shared/companies/fieldsets/_social.html.erb:82:31
// app/views/shared/companies/fieldsets/_social.html.erb:98:31
const SharedFormFormInputGroup = lazy(() => import('./shared/form/FormInputGroup' ));

// app/views/external/job_postings/applications/fieldsets/_job_preferences.html.erb:14:31
// app/views/external/job_postings/applications/fieldsets/_job_preferences.html.erb:35:31
const SharedFormJobPreferences = lazy(() => import('./shared/form/JobPreferences' ));

// app/views/external/job_seekers/fieldsets/_structures.html.erb:52:39
// app/views/external/job_seekers/fieldsets/_teams.html.erb:44:29
// app/views/external/surveys/_form.html.erb:157:41
const SharedFormMultipleChoiceSelectField = lazy(() => import('./shared/form/MultipleChoiceSelectField' ));

// app/views/external/job_postings/applications/fieldsets/_details.html.erb:86:33
// app/views/external/job_seekers/fieldsets/_personal_details.html.erb:51:35
// app/views/shared/companies/fieldsets/_contact.html.erb:25:33
const SharedFormPhoneNumberInput = lazy(() => import('./shared/form/PhoneNumberInput' ));

// app/views/external/job_seekers/fieldsets/_structures.html.erb:41:39
// app/views/external/surveys/_form.html.erb:144:41
const SharedFormSelectField = lazy(() => import('./shared/form/SelectField' ));

// app/views/external/job_postings/applications/fieldsets/_structured_sections.html.erb:28:35
// app/views/external/job_seekers/fieldsets/_structured_sections.html.erb:28:35
const SharedFormStructuredSectionResponses = lazy(() => import('./shared/form/StructuredSectionResponses' ));

// app/views/external/surveys/_form.html.erb:230:39
const SharedFormUrlInput = lazy(() => import('./shared/form/UrlInput' ));

// app/views/external/candidates/additional_informations/new.html.erb:99:49
// app/views/external/candidates/question_collections/new.html.erb:102:49
// app/views/external/shared/_questions.html.erb:54:37
const SharedFormQuestionsBoolean = lazy(() => import('./shared/form/questions/Boolean' ));

// app/views/external/candidates/additional_informations/new.html.erb:138:49
// app/views/external/candidates/question_collections/new.html.erb:158:49
// app/views/external/shared/_questions.html.erb:110:37
const SharedFormQuestionsDateSelect = lazy(() => import('./shared/form/questions/DateSelect' ));

// app/views/external/candidates/additional_informations/new.html.erb:117:49
// app/views/external/candidates/question_collections/new.html.erb:120:49
// app/views/external/shared/_questions.html.erb:72:37
const SharedFormQuestionsDocument = lazy(() => import('./shared/form/questions/Document' ));

// app/views/external/candidates/additional_informations/new.html.erb:68:49
// app/views/external/candidates/question_collections/new.html.erb:71:49
// app/views/external/shared/_questions.html.erb:23:37
const SharedFormQuestionsLongText = lazy(() => import('./shared/form/questions/LongText' ));

// app/views/external/shared/questions/_multiple_choice.html.erb:30:27
const SharedFormQuestionsMultiSelect = lazy(() => import('./shared/form/questions/MultiSelect' ));

// app/views/external/candidates/question_collections/new.html.erb:141:49
// app/views/external/shared/_questions.html.erb:93:37
const SharedFormQuestionsMultipleDocuments = lazy(() => import('./shared/form/questions/MultipleDocuments' ));

// app/views/external/candidates/additional_informations/new.html.erb:172:49
// app/views/external/candidates/question_collections/new.html.erb:192:49
// app/views/external/shared/_questions.html.erb:144:37
const SharedFormQuestionsNumberInput = lazy(() => import('./shared/form/questions/NumberInput' ));

// app/views/external/candidates/additional_informations/new.html.erb:83:49
// app/views/external/candidates/question_collections/new.html.erb:86:49
// app/views/external/shared/_questions.html.erb:38:37
const SharedFormQuestionsShortText = lazy(() => import('./shared/form/questions/ShortText' ));

// app/views/external/shared/questions/_multiple_choice.html.erb:10:27
const SharedFormQuestionsSingleSelect = lazy(() => import('./shared/form/questions/SingleSelect' ));

// app/views/external/candidates/additional_informations/new.html.erb:155:49
// app/views/external/candidates/question_collections/new.html.erb:175:49
// app/views/external/shared/_questions.html.erb:127:37
const SharedFormQuestionsUrl = lazy(() => import('./shared/form/questions/Url' ));

// app/views/external/shared/blocks/_careers_location_and_map_block.html.erb:8:31
const SharedGoogleMap = lazy(() => import('./shared/google/Map' ));

// app/views/layouts/admin/_desktop_nav.html.erb:13:33
// app/views/layouts/referrals/_desktop_nav.html.erb:18:33
const SharedLayoutCompanyDropdownMenu = lazy(() => import('./shared/layout/CompanyDropdownMenu' ));

// app/views/layouts/fullscreen/_header.html.erb:6:31
const SharedNavigationBackLink = lazy(() => import('./shared/navigation/BackLink' ));

// app/views/layouts/admin/_sidebar.html.erb:4:31
// app/views/layouts/platform/_mobile_nav.html.erb:25:33
// app/views/layouts/platform/_sidebar.html.erb:3:29
// app/views/layouts/referrals/_sidebar.html.erb:3:29
const SharedNavigationNavigation = lazy(() => import('./shared/navigation/Navigation' ));

// app/views/layouts/admin/_two_column_sidebar.erb:2:27
const SharedNavigationPrimarySidebarNavigation = lazy(() => import('./shared/navigation/PrimarySidebarNavigation' ));

// app/views/layouts/admin/_two_column_sidebar.erb:11:31
const SharedNavigationSecondarySidebarNavigation = lazy(() => import('./shared/navigation/SecondarySidebarNavigation' ));

// app/views/admin/jobs/stages/interview_invites/index.html.erb:27:35
// app/views/devise/sessions/two_factor.html.erb:21:27
// app/views/extensions/chrome/admin/sourcings/show.html.erb:8:27
// app/views/extensions/chrome/users/sessions/new.html.erb:9:27
// app/views/extensions/chrome/users/sessions/two_factor.html.erb:21:27
// app/views/external/equality_monitorings/surveys/new.html.erb:28:37
// app/views/external/group_assessment_attendees/new.html.erb:31:37
// app/views/external/group_interview_attendees/new.html.erb:31:37
// app/views/external/job_postings/applications/fieldsets/_submit.html.erb:56:37
// app/views/external/job_postings/applications/new.html.erb:35:41
// app/views/external/job_postings/applications/new.html.erb:48:43
// app/views/external/job_postings/applications/new.html.erb:93:41
// app/views/external/job_postings/show/_closed_job_alert.html.erb:3:27
// app/views/external/job_postings/show/_internal_job_alert.html.erb:3:27
// app/views/external/job_seekers/new.html.erb:34:39
// app/views/platform/companies/seeding/index.html.erb:33:43
// app/views/platform/companies/seeding/index.html.erb:299:43
// app/views/shared/_error_list.html.erb:1:25
// app/views/shared/_flash_callout.html.erb:3:27
// app/views/shared/_readonly_callout.html.erb:4:29
// app/views/shared/companies/fieldsets/_info.html.erb:56:37
const SharedThumbtackCalloutSimpleCallout = lazy(() => import('./shared/thumbtack/callout/SimpleCallout' ));

// app/views/shared/companies/fieldsets/_info.html.erb:236:35
const SharedThumbtackFormTimeZonePicker = lazy(() => import('./shared/thumbtack/form/TimeZonePicker' ));

// app/views/platform/companies/job_seekers/fieldsets/_consent.html.erb:16:31
// app/views/platform/companies/seeding/job_seekers/fieldsets/_consent.html.erb:16:31
const SharedThumbtackFormFieldsUncontrolledDateField = lazy(() => import('./shared/thumbtack/form/fields/UncontrolledDateField' ));

// app/views/platform/companies/show.html.erb:31:41
// app/views/platform/companies/show.html.erb:36:41
const SharedThumbtackTextCopyTextCopy = lazy(() => import('./shared/thumbtack/textCopy/TextCopy' ));

// app/views/admin/onboarding/workflows/templates/show.html.erb:54:29
// app/views/extensions/chrome/admin/sourcings/show.html.erb:28:27
// app/views/external/approval_workflow_stages_users/approve.html.erb:79:25
// app/views/external/document_requests/accept.html.erb:61:25
// app/views/external/offers/accept.html.erb:92:25
// app/views/layouts/application.html.erb:50:27
// app/views/layouts/devise.html.erb:29:27
// app/views/layouts/fullscreen.html.erb:31:25
// app/views/layouts/platform.html.erb:27:27
// app/views/layouts/referrals.html.erb:27:27
// app/views/onboarding/candidate_responses/show.html.erb:58:29
// app/views/onboarding/processes/show.html.erb:65:29
// app/views/onboarding/team_member_actions/show.html.erb:63:29
// app/views/onboarding/team_member_forms/show.html.erb:61:29
// app/views/onboarding/workflows/show.html.erb:58:29
const SharedThumbtackToastsFlashToaster = lazy(() => import('./shared/thumbtack/toasts/FlashToaster' ));

// app/views/two_factor_authentication/recovery/new.html.erb:3:25
const TwoFactorAuthenticationRecover = lazy(() => import('./twoFactorAuthentication/Recover' ));


export const components = {
  'Admin::Approvalworkflowwidget': AdminApprovalWorkflowWidget,
  'Admin::Candidates': AdminCandidates,
  'Admin::Careerssite': AdminCareersSite,
  'Admin::Customfields': AdminCustomFields,
  'Admin::Dashboard': AdminDashboard,
  'Admin::Employeedashboard': AdminEmployeeDashboard,
  'Admin::Facebookconfiguration': AdminFacebookConfiguration,
  'Admin::Groupassessment': AdminGroupAssessment,
  'Admin::Interview': AdminInterview,
  'Admin::Jobboardpostings': AdminJobBoardPostings,
  'Admin::Jobseekers': AdminJobSeekers,
  'Admin::Newuserwizard': AdminNewUserWizard,
  'Admin::Approvals::Allapprovals': AdminApprovalsAllApprovals,
  'Admin::Approvals::Myapprovals': AdminApprovalsMyApprovals,
  'Admin::Automations::Automationsrouter': AdminAutomationsAutomationsRouter,
  'Admin::CandidateSurveys::Configuration': AdminCandidateSurveysConfiguration,
  'Admin::Candidates::Show': AdminCandidatesShow,
  'Admin::Careerssite::Activetoggle': AdminCareersSiteActiveToggle,
  'Admin::Careerssite::Careerscustomcss': AdminCareersSiteCareersCustomCss,
  'Admin::Careerssite::Careerscustomconversionpixel': AdminCareersSiteCareersCustomConversionPixel,
  'Admin::Careerssite::Careerscustomhomelink': AdminCareersSiteCareersCustomHomeLink,
  'Admin::Careerssite::Careerscustominteresttext': AdminCareersSiteCareersCustomInterestText,
  'Admin::Careerssite::Careerscustomnextstepstext': AdminCareersSiteCareersCustomNextStepsText,
  'Admin::Careerssite::Careerscustomrobots': AdminCareersSiteCareersCustomRobots,
  'Admin::Careerssite::Careersdraftconfirm': AdminCareersSiteCareersDraftConfirm,
  'Admin::Careerssite::Careersfacebookpixeldomainverification': AdminCareersSiteCareersFacebookPixelDomainVerification,
  'Admin::Careerssite::Careersindeedconversionpixel': AdminCareersSiteCareersIndeedConversionPixel,
  'Admin::Careerssite::Careerslinkedinconversionpixel': AdminCareersSiteCareersLinkedinConversionPixel,
  'Admin::Careerssite::Careersnavigation': AdminCareersSiteCareersNavigation,
  'Admin::Careerssite::Careerspages': AdminCareersSiteCareersPages,
  'Admin::Careerssite::Careerstalentconversionpixel': AdminCareersSiteCareersTalentConversionPixel,
  'Admin::Careerssite::Careersziprecruiterconversionpixel': AdminCareersSiteCareersZipRecruiterConversionPixel,
  'Admin::Careerssite::Createdraftcareerssite': AdminCareersSiteCreateDraftCareersSite,
  'Admin::Careerssite::Disabledform': AdminCareersSiteDisabledForm,
  'Admin::Careerssite::Favicon': AdminCareersSiteFavicon,
  'Admin::Careerssite::Header': AdminCareersSiteHeader,
  'Admin::Careerssite::Maxapplicationswarning': AdminCareersSiteMaxApplicationsWarning,
  'Admin::Careerssite::Opengraph::Form': AdminCareersSiteOpenGraphForm,
  'Admin::Companies::Dataretentionpolicies': AdminCompaniesDataRetentionPolicies,
  'Admin::Companies::Preferences': AdminCompaniesPreferences,
  'Admin::Companies::Integrations::Cronofyenterpriseconnect': AdminCompaniesIntegrationsCronofyEnterpriseConnect,
  'Admin::Company::Details::Customdomainredirects': AdminCompanyDetailsCustomDomainRedirects,
  'Admin::Company::Themes::Editthemes': AdminCompanyThemesEditThemes,
  'Admin::Equalitymonitoring::Configuration': AdminEqualityMonitoringConfiguration,
  'Admin::Groupassessments::Table': AdminGroupAssessmentsTable,
  'Admin::Insights::Filters': AdminInsightsFilters,
  'Admin::Insights::Insightshub': AdminInsightsInsightsHub,
  'Admin::Insights::Reports': AdminInsightsReports,
  'Admin::Insights::Candidatesurveys::Export': AdminInsightsCandidateSurveysExport,
  'Admin::Insights::Insightshub::Displayinsights': AdminInsightsInsightsHubDisplayInsights,
  'Admin::Integrations::Editintegration': AdminIntegrationsEditIntegration,
  'Admin::Integrations::Integrationmarketplace': AdminIntegrationsIntegrationMarketplace,
  'Admin::Integrations::Integrationsmanager': AdminIntegrationsIntegrationsManager,
  'Admin::Internsprogramme::Dashboard': AdminInternsProgrammeDashboard,
  'Admin::Jobapplications::Index': AdminJobApplicationsIndex,
  'Admin::Jobseekers::Jobseekersecondarytabs': AdminJobSeekersJobSeekerSecondaryTabs,
  'Admin::Jobseekers::Share': AdminJobSeekersShare,
  'Admin::Jobseekers::Scorecards::Scorecardreviews': AdminJobSeekersScorecardsScorecardReviews,
  'Admin::Jobseekers::Show::Overview': AdminJobSeekersShowOverview,
  'Admin::Jobs::Applicationform': AdminJobsApplicationForm,
  'Admin::Jobs::Draftapproval': AdminJobsDraftApproval,
  'Admin::Jobs::Facebookadsets': AdminJobsFacebookAdsets,
  'Admin::Jobs::Facebookadvert': AdminJobsFacebookAdvert,
  'Admin::Jobs::Facebookcampaigns': AdminJobsFacebookCampaigns,
  'Admin::Jobs::Groupinterviews': AdminJobsGroupInterviews,
  'Admin::Jobs::Index': AdminJobsIndex,
  'Admin::Jobs::Interviews': AdminJobsInterviews,
  'Admin::Jobs::Jobinformation': AdminJobsJobInformation,
  'Admin::Jobs::Jobpostings': AdminJobsJobPostings,
  'Admin::Jobs::Linkedinpostings': AdminJobsLinkedInPostings,
  'Admin::Jobs::Offerlettertemplateedit': AdminJobsOfferLetterTemplateEdit,
  'Admin::Jobs::Positions': AdminJobsPositions,
  'Admin::Jobs::Scorecardreviews': AdminJobsScorecardReviews,
  'Admin::Jobs::Share': AdminJobsShare,
  'Admin::Jobs::Titleheader': AdminJobsTitleHeader,
  'Admin::Jobs::Adverts::Jobboards': AdminJobsAdvertsJobBoards,
  'Admin::Jobs::Applications::Applicationshow': AdminJobsApplicationsApplicationShow,
  'Admin::Jobs::Applications::Applications': AdminJobsApplicationsApplications,
  'Admin::Jobs::Applications::Jobapplicationsnav': AdminJobsApplicationsJobApplicationsNav,
  'Admin::Jobs::Applications::Index::Lanes': AdminJobsApplicationsIndexLanes,
  'Admin::Jobs::Facebookadvert::Facebookpreview': AdminJobsFacebookAdvertFacebookPreview,
  'Admin::Jobs::Facebookadvert::Reports': AdminJobsFacebookAdvertReports,
  'Admin::Jobs::Forms::Jobform': AdminJobsFormsJobForm,
  'Admin::Jobs::Groupinterviews::Show': AdminJobsGroupInterviewsShow,
  'Admin::Jobs::Integrations::Editcvlibraryposting': AdminJobsIntegrationsEditCvLibraryPosting,
  'Admin::Jobs::Integrations::Editreedposting': AdminJobsIntegrationsEditReedPosting,
  'Admin::Jobs::Integrations::Editseekposting': AdminJobsIntegrationsEditSeekPosting,
  'Admin::Jobs::Integrations::Edittotaljobsposting': AdminJobsIntegrationsEditTotalJobsPosting,
  'Admin::Jobs::Integrations::Integrationsindex': AdminJobsIntegrationsIntegrationsIndex,
  'Admin::Jobs::Integrations::Newcvlibraryposting': AdminJobsIntegrationsNewCvLibraryPosting,
  'Admin::Jobs::Integrations::Newreedposting': AdminJobsIntegrationsNewReedPosting,
  'Admin::Jobs::Integrations::Newseekposting': AdminJobsIntegrationsNewSeekPosting,
  'Admin::Jobs::Integrations::Newtotaljobsposting': AdminJobsIntegrationsNewTotalJobsPosting,
  'Admin::Jobs::Jobboardpostings::Naukri::Postingform': AdminJobsJobBoardPostingsNaukriPostingForm,
  'Admin::Jobs::Jobseekers::Jobseekers': AdminJobsJobSeekersJobSeekers,
  'Admin::Jobs::Share::Qrcodes': AdminJobsShareQrCodes,
  'Admin::Jobs::Share::Sharinglinks': AdminJobsShareSharingLinks,
  'Admin::Jobs::Stages::Edit': AdminJobsStagesEdit,
  'Admin::Jobs::Stages::Videointerview': AdminJobsStagesVideoInterview,
  'Admin::Jobs::Stages::Interviewinvites::Videoinvites': AdminJobsStagesInterviewInvitesVideoInvites,
  'Admin::Jobs::Talentpipelines::Jobseekers': AdminJobsTalentPipelinesJobSeekers,
  'Admin::Jobs::Team::Jobpermissions': AdminJobsTeamJobPermissions,
  'Admin::Layout::Awaystatustag': AdminLayoutAwayStatusTag,
  'Admin::Layout::Footer': AdminLayoutFooter,
  'Admin::Layout::Mobilenavigation': AdminLayoutMobileNavigation,
  'Admin::Layout::Notifications': AdminLayoutNotifications,
  'Admin::Layout::Profilenav': AdminLayoutProfileNav,
  'Admin::Layout::Desktop::Desktopnotifications': AdminLayoutDesktopDesktopNotifications,
  'Admin::Layout::Desktop::Desktoptasks': AdminLayoutDesktopDesktopTasks,
  'Admin::Onboarding::Candidates': AdminOnboardingCandidates,
  'Admin::Onboarding::Dashboard': AdminOnboardingDashboard,
  'Admin::Onboarding::Portals': AdminOnboardingPortals,
  'Admin::Onboarding::Processes': AdminOnboardingProcesses,
  'Admin::Onboarding::Stages': AdminOnboardingStages,
  'Admin::Onboarding::Workflows': AdminOnboardingWorkflows,
  'Admin::Onboarding::Candidates::Settings': AdminOnboardingCandidatesSettings,
  'Admin::Onboarding::Candidates::Show': AdminOnboardingCandidatesShow,
  'Admin::Onboarding::Previews::Workflow': AdminOnboardingPreviewsWorkflow,
  'Admin::Onboarding::Processes::Candidates': AdminOnboardingProcessesCandidates,
  'Admin::Onboarding::Processes::Steps::Steplibrary': AdminOnboardingProcessesStepsStepLibrary,
  'Admin::Onboarding::Workflows::Templates': AdminOnboardingWorkflowsTemplates,
  'Admin::Organisation::Permissions': AdminOrganisationPermissions,
  'Admin::Organisation::Postinglocations': AdminOrganisationPostingLocations,
  'Admin::Organisation::Structure': AdminOrganisationStructure,
  'Admin::Positionmanagement::Positions': AdminPositionManagementPositions,
  'Admin::Referrals::Referrals': AdminReferralsReferrals,
  'Admin::Requisitions::Edit': AdminRequisitionsEdit,
  'Admin::Requisitions::Requisitionform': AdminRequisitionsRequisitionForm,
  'Admin::Requisitions::Requisitionsindex': AdminRequisitionsRequisitionsIndex,
  'Admin::Requisitions::Show': AdminRequisitionsShow,
  'Admin::Settings::Availability': AdminSettingsAvailability,
  'Admin::Settings::Integrations': AdminSettingsIntegrations,
  'Admin::Settings::Account::Basicdetails': AdminSettingsAccountBasicDetails,
  'Admin::Settings::Account::Editpassword': AdminSettingsAccountEditPassword,
  'Admin::Settings::Integrations::Cronofy::Cronofy': AdminSettingsIntegrationsCronofyCronofy,
  'Admin::Settings::Notifications::Preferences': AdminSettingsNotificationsPreferences,
  'Admin::Settings::Profile::Currentprofileform': AdminSettingsProfileCurrentProfileForm,
  'Admin::Settings::Security::Twofactorauthentication::Configuration': AdminSettingsSecurityTwoFactorAuthenticationConfiguration,
  'Admin::Settings::Templates::Documenttemplates': AdminSettingsTemplatesDocumentTemplates,
  'Admin::Settings::Templates::Emailtemplates': AdminSettingsTemplatesEmailTemplates,
  'Admin::Settings::Templates::Equalitymonitoringtemplates': AdminSettingsTemplatesEqualityMonitoringTemplates,
  'Admin::Settings::Templates::Hiringworkflows': AdminSettingsTemplatesHiringWorkflows,
  'Admin::Settings::Templates::Interviewtypes': AdminSettingsTemplatesInterviewTypes,
  'Admin::Settings::Templates::Jobgroups': AdminSettingsTemplatesJobGroups,
  'Admin::Settings::Templates::Jobrequisitions': AdminSettingsTemplatesJobRequisitions,
  'Admin::Settings::Templates::Jobtemplates': AdminSettingsTemplatesJobTemplates,
  'Admin::Settings::Templates::Offertemplates': AdminSettingsTemplatesOfferTemplates,
  'Admin::Settings::Templates::Onboardingquestions': AdminSettingsTemplatesOnboardingQuestions,
  'Admin::Settings::Templates::Questionbank': AdminSettingsTemplatesQuestionBank,
  'Admin::Settings::Templates::Questioncollections': AdminSettingsTemplatesQuestionCollections,
  'Admin::Settings::Templates::Scorecards': AdminSettingsTemplatesScorecards,
  'Admin::Settings::Templates::Signoffworkflows': AdminSettingsTemplatesSignOffWorkflows,
  'Admin::Settings::Templates::Smstemplates': AdminSettingsTemplatesSmsTemplates,
  'Admin::Settings::Templates::Structuredsections': AdminSettingsTemplatesStructuredSections,
  'Admin::Settings::Templates::Workflowautomations': AdminSettingsTemplatesWorkflowAutomations,
  'Admin::Shared::Basicresponsivesecondarytabs': AdminSharedBasicResponsiveSecondaryTabs,
  'Admin::Shared::Formlanguagedropdown': AdminSharedFormLanguageDropdown,
  'Admin::Shared::Interviewinvites': AdminSharedInterviewInvites,
  'Admin::Shared::Primarytabs': AdminSharedPrimaryTabs,
  'Admin::Shared::Secondarylinktabs': AdminSharedSecondaryLinkTabs,
  'Admin::Shared::Candidates::Actions::Sendtoqueue::Queueactions': AdminSharedCandidatesActionsSendToQueueQueueActions,
  'Admin::Shared::Tasks::Iconindicator': AdminSharedTasksIconIndicator,
  'Admin::Shared::Widgets::Equalitymonitoringcategories': AdminSharedWidgetsEqualityMonitoringCategories,
  'Admin::Shared::Widgets::Candidatesurveys::Candidateexperiencescore': AdminSharedWidgetsCandidateSurveysCandidateExperienceScore,
  'Admin::Shared::Widgets::Candidatesurveys::Candidateexperiencescoreovertime': AdminSharedWidgetsCandidateSurveysCandidateExperienceScoreOverTime,
  'Admin::Shared::Widgets::Candidatesurveys::Comments': AdminSharedWidgetsCandidateSurveysComments,
  'Admin::Shared::Widgets::Candidatesurveys::Comparison': AdminSharedWidgetsCandidateSurveysComparison,
  'Admin::Shared::Widgets::Candidatesurveys::Metrics::Surveyresponserate': AdminSharedWidgetsCandidateSurveysMetricsSurveyResponseRate,
  'Admin::Shared::Widgets::Candidatesurveys::Metrics::Totalresponses': AdminSharedWidgetsCandidateSurveysMetricsTotalResponses,
  'Admin::Shared::Widgets::Candidates::Applicanttagbreakdown': AdminSharedWidgetsCandidatesApplicantTagBreakdown,
  'Admin::Shared::Widgets::Candidates::Applicantsbychannel': AdminSharedWidgetsCandidatesApplicantsByChannel,
  'Admin::Shared::Widgets::Candidates::Applicationsbydatecreated': AdminSharedWidgetsCandidatesApplicationsByDateCreated,
  'Admin::Shared::Widgets::Candidates::Applicationsbystage': AdminSharedWidgetsCandidatesApplicationsByStage,
  'Admin::Shared::Widgets::Candidates::Hiredbychannel': AdminSharedWidgetsCandidatesHiredByChannel,
  'Admin::Shared::Widgets::Candidates::Outboundemaildistribution': AdminSharedWidgetsCandidatesOutboundEmailDistribution,
  'Admin::Shared::Widgets::Candidates::Recruiterapplicationsbysubmission': AdminSharedWidgetsCandidatesRecruiterApplicationsBySubmission,
  'Admin::Shared::Widgets::Candidates::Metrics::Averagecountperjob': AdminSharedWidgetsCandidatesMetricsAverageCountPerJob,
  'Admin::Shared::Widgets::Candidates::Metrics::Averagecountperjobwithhire': AdminSharedWidgetsCandidatesMetricsAverageCountPerJobWithHire,
  'Admin::Shared::Widgets::Candidates::Metrics::Percenthired': AdminSharedWidgetsCandidatesMetricsPercentHired,
  'Admin::Shared::Widgets::Comments::Byuser': AdminSharedWidgetsCommentsByUser,
  'Admin::Shared::Widgets::Interviews::Byinterviewtypeinterviewtype': AdminSharedWidgetsInterviewsByInterviewTypeInterviewType,
  'Admin::Shared::Widgets::Interviews::Byinterviewtypename': AdminSharedWidgetsInterviewsByInterviewTypeName,
  'Admin::Shared::Widgets::Interviews::Bytimeperiod': AdminSharedWidgetsInterviewsByTimePeriod,
  'Admin::Shared::Widgets::Jobseekers::Talentpipelinebychannel': AdminSharedWidgetsJobSeekersTalentPipelineByChannel,
  'Admin::Shared::Widgets::Jobseekers::Talentpipelinebydatecreated': AdminSharedWidgetsJobSeekersTalentPipelineByDateCreated,
  'Admin::Shared::Widgets::Jobseekers::Talentpipelinetagbreakdown': AdminSharedWidgetsJobSeekersTalentPipelineTagBreakdown,
  'Admin::Shared::Widgets::Jobs::Applicationsbyjobposting': AdminSharedWidgetsJobsApplicationsByJobPosting,
  'Admin::Shared::Widgets::Jobs::Applicationsbyjobstage': AdminSharedWidgetsJobsApplicationsByJobStage,
  'Admin::Shared::Widgets::Jobs::Averagetimespentinstages': AdminSharedWidgetsJobsAverageTimeSpentInStages,
  'Admin::Shared::Widgets::Jobs::Averagetimetofill': AdminSharedWidgetsJobsAverageTimeToFill,
  'Admin::Shared::Widgets::Jobs::Averagetimetohire': AdminSharedWidgetsJobsAverageTimeToHire,
  'Admin::Shared::Widgets::Jobs::Bystatus': AdminSharedWidgetsJobsByStatus,
  'Admin::Shared::Widgets::Jobs::Furtheststagereached': AdminSharedWidgetsJobsFurthestStageReached,
  'Admin::Shared::Widgets::Jobs::Jobopenduration': AdminSharedWidgetsJobsJobOpenDuration,
  'Admin::Shared::Widgets::Jobs::Numberofapplicants': AdminSharedWidgetsJobsNumberOfApplicants,
  'Admin::Shared::Widgets::Jobs::Numberofapplications': AdminSharedWidgetsJobsNumberOfApplications,
  'Admin::Shared::Widgets::Jobs::Timetofill': AdminSharedWidgetsJobsTimeToFill,
  'Admin::Shared::Widgets::Jobs::Timetohire': AdminSharedWidgetsJobsTimeToHire,
  'Admin::Shared::Widgets::Keymetrics::Averageinterviewteammembersbystage': AdminSharedWidgetsKeyMetricsAverageInterviewTeamMembersByStage,
  'Admin::Shared::Widgets::Keymetrics::Averagemessagesperapplicant': AdminSharedWidgetsKeyMetricsAverageMessagesPerApplicant,
  'Admin::Shared::Widgets::Keymetrics::Averagetimebeforefirstcandidatecommunication': AdminSharedWidgetsKeyMetricsAverageTimeBeforeFirstCandidateCommunication,
  'Admin::Shared::Widgets::Keymetrics::Averagetimebetweencandidatecommunication': AdminSharedWidgetsKeyMetricsAverageTimeBetweenCandidateCommunication,
  'Admin::Shared::Widgets::Keymetrics::Candidatemessagecountdistribution': AdminSharedWidgetsKeyMetricsCandidateMessageCountDistribution,
  'Admin::Shared::Widgets::Keymetrics::Percentageintervieweeshired': AdminSharedWidgetsKeyMetricsPercentageIntervieweesHired,
  'Admin::Shared::Widgets::Messages::Byuser': AdminSharedWidgetsMessagesByUser,
  'Admin::Shared::Widgets::Users::Signins': AdminSharedWidgetsUsersSignIns,
  'Admin::Tagmanagement::Tagmanagement': AdminTagManagementTagManagement,
  'Admin::Talentpipeline::Jobseekerform': AdminTalentPipelineJobSeekerForm,
  'Admin::Talentpipeline::Pools': AdminTalentPipelinePools,
  'Admin::Talentpipeline::Scorecardablescorecards': AdminTalentPipelineScorecardableScorecards,
  'Admin::Talentpipeline::Interviewopenings::Calendar': AdminTalentPipelineInterviewOpeningsCalendar,
  'Admin::Talentpipeline::Settings::Form': AdminTalentPipelineSettingsForm,
  'Admin::Talentpipeline::Settings::Interviews::Configuration': AdminTalentPipelineSettingsInterviewsConfiguration,
  'Admin::Usermanagement::Accessgroups': AdminUserManagementAccessGroups,
  'Admin::Usermanagement::Agencies': AdminUserManagementAgencies,
  'Admin::Usermanagement::Agencyusers': AdminUserManagementAgencyUsers,
  'Admin::Usermanagement::Approvallists': AdminUserManagementApprovalLists,
  'Admin::Usermanagement::Companyteams': AdminUserManagementCompanyTeams,
  'Admin::Usermanagement::Nonuseremployees': AdminUserManagementNonUserEmployees,
  'Admin::Usermanagement::Recruiters': AdminUserManagementRecruiters,
  'Admin::Usermanagement::Users': AdminUserManagementUsers,
  'Admin::Usermanagement::Employees::Invite': AdminUserManagementEmployeesInvite,
  'Admin::Webhooks::Endpoint': AdminWebhooksEndpoint,
  'Admin::Webhooks::Endpoints': AdminWebhooksEndpoints,
  'Companies::Empty': CompaniesEmpty,
  'Companies::Languagesfield': CompaniesLanguagesField,
  'Companies::Security': CompaniesSecurity,
  'Companies::Websiteinput': CompaniesWebsiteInput,
  'Devise::Backtologin': DeviseBackToLogin,
  'Devise::Loggedout': DeviseLoggedOut,
  'Devise::Login': DeviseLogin,
  'Devise::Passwordreset': DevisePasswordReset,
  'Devise::Setpassword': DeviseSetPassword,
  'Devise::Twofactor': DeviseTwoFactor,
  'Devise::Extensions::Chrome::Login': DeviseExtensionsChromeLogin,
  'Devise::Extensions::Chrome::Twofactor': DeviseExtensionsChromeTwoFactor,
  'Extensions::Chrome::Sourcing': ExtensionsChromeSourcing,
  'External::Filters': ExternalFilters,
  'External::Jobs': ExternalJobs,
  'External::Approvals::Assignorskip': ExternalApprovalsAssignOrSkip,
  'External::Careerspage::Carousel': ExternalCareersPageCarousel,
  'External::Careerspage::Imagegallery': ExternalCareersPageImageGallery,
  'External::Careerspage::Jobsfilter': ExternalCareersPageJobsFilter,
  'External::Careerspage::Renderrichtrixcontent': ExternalCareersPageRenderRichTrixContent,
  'External::Careerspage::Responsivenavigation': ExternalCareersPageResponsiveNavigation,
  'External::Careerspage::Video': ExternalCareersPageVideo,
  'External::Careerspage::Videohero': ExternalCareersPageVideoHero,
  'External::DocumentRequests::Sign': ExternalDocumentRequestsSign,
  'External::Groupassessments::Reschedulingoptions': ExternalGroupAssessmentsReschedulingOptions,
  'External::Interviews::Schedule': ExternalInterviewsSchedule,
  'External::Interviews::Timezone': ExternalInterviewsTimeZone,
  'External::Interviews::Group::Schedule': ExternalInterviewsGroupSchedule,
  'External::Jobs::Documentcard': ExternalJobsDocumentCard,
  'External::Jobs::Hiringprocesssidebar': ExternalJobsHiringProcessSidebar,
  'External::Jobs::Stagescarousel': ExternalJobsStagesCarousel,
  'External::Jobs::Applications::New': ExternalJobsApplicationsNew,
  'External::Jobs::Equalitymonitoring::Tsselect': ExternalJobsEqualityMonitoringTsSelect,
  'External::Offers::Sign': ExternalOffersSign,
  'Integrations::Providers::Rippling::Install': IntegrationsProvidersRipplingInstall,
  'Mydata::Registeredinterest': MydataRegisteredInterest,
  'Mydata::Submittedapplications': MydataSubmittedApplications,
  'Onboarding::Candidateresponse': OnboardingCandidateResponse,
  'Onboarding::Process': OnboardingProcess,
  'Onboarding::Teammemberaction': OnboardingTeamMemberAction,
  'Onboarding::Teammemberform': OnboardingTeamMemberForm,
  'Onboarding::Workflow': OnboardingWorkflow,
  'Onboarding::Process::Candidateheaderinfo': OnboardingProcessCandidateHeaderInfo,
  'Onboarding::Process::Mobilecandidateheaderinfo': OnboardingProcessMobileCandidateHeaderInfo,
  'Platform::Aiassistantconversations': PlatformAiAssistantConversations,
  'Platform::Inboundemails': PlatformInboundEmails,
  'Platform::Limitedaccessgrants': PlatformLimitedAccessGrants,
  'Platform::Referralsusers': PlatformReferralsUsers,
  'Platform::Targetingtemplate': PlatformTargetingTemplate,
  'Platform::Adverts::Jobboards': PlatformAdvertsJobBoards,
  'Platform::Companies::Automations': PlatformCompaniesAutomations,
  'Platform::Companies::Careerssites': PlatformCompaniesCareersSites,
  'Platform::Companies::Companylinksmenu': PlatformCompaniesCompanyLinksMenu,
  'Platform::Companies::Deletedjobs': PlatformCompaniesDeletedJobs,
  'Platform::Companies::Demotoggle': PlatformCompaniesDemoToggle,
  'Platform::Companies::Grantcareerssiteaccess': PlatformCompaniesGrantCareersSiteAccess,
  'Platform::Companies::Index': PlatformCompaniesIndex,
  'Platform::Companies::Linkedinpostings': PlatformCompaniesLinkedInPostings,
  'Platform::Companies::Onboardingapplications': PlatformCompaniesOnboardingApplications,
  'Platform::Companies::Outboundrequests': PlatformCompaniesOutboundRequests,
  'Platform::Companies::Packages': PlatformCompaniesPackages,
  'Platform::Companies::Primarytabs': PlatformCompaniesPrimaryTabs,
  'Platform::Companies::Requisitions': PlatformCompaniesRequisitions,
  'Platform::Companies::Settings': PlatformCompaniesSettings,
  'Platform::Companies::Stages': PlatformCompaniesStages,
  'Platform::Companies::Users': PlatformCompaniesUsers,
  'Platform::Companies::Videointerviews': PlatformCompaniesVideoInterviews,
  'Platform::Companies::Vonqcampaigns': PlatformCompaniesVonqCampaigns,
  'Platform::Companies::Webhookendpoints': PlatformCompaniesWebhookEndpoints,
  'Platform::Companies::Careerssites::Show': PlatformCompaniesCareersSitesShow,
  'Platform::Companies::Forms::Companyform': PlatformCompaniesFormsCompanyForm,
  'Platform::Companies::Reports::Job': PlatformCompaniesReportsJob,
  'Platform::Companies::Reports::Jobs': PlatformCompaniesReportsJobs,
  'Platform::Companies::Requisitions::Actions': PlatformCompaniesRequisitionsActions,
  'Platform::Companies::Seeding::Equalitymonitoringtraits': PlatformCompaniesSeedingEqualityMonitoringTraits,
  'Platform::Companies::Show::Editablebillingfields': PlatformCompaniesShowEditableBillingFields,
  'Platform::Companies::Show::Editablecountry': PlatformCompaniesShowEditableCountry,
  'Platform::Companies::Show::Editablecurrency': PlatformCompaniesShowEditableCurrency,
  'Platform::Companies::Show::Editabletimezone': PlatformCompaniesShowEditableTimeZone,
  'Platform::Companies::Templates::Interviewtypes': PlatformCompaniesTemplatesInterviewTypes,
  'Platform::Companies::Templates::Reporttemplates': PlatformCompaniesTemplatesReportTemplates,
  'Platform::Companies::Users::Show': PlatformCompaniesUsersShow,
  'Platform::Databaseinfo::Show': PlatformDatabaseInfoShow,
  'Platform::Deals::Index': PlatformDealsIndex,
  'Platform::Delayedjobs::Index': PlatformDelayedJobsIndex,
  'Platform::Insights::Twiliointegrations': PlatformInsightsTwilioIntegrations,
  'Platform::Integrationdescription::Index': PlatformIntegrationDescriptionIndex,
  'Platform::Jobseekers::Createdemojobseekers': PlatformJobSeekersCreateDemoJobSeekers,
  'Platform::Jobs::Companies::Actions': PlatformJobsCompaniesActions,
  'Platform::Jobs::Companies::Jobs': PlatformJobsCompaniesJobs,
  'Platform::Layout::Companyselector': PlatformLayoutCompanySelector,
  'Platform::Platformadmins::Index': PlatformPlatformAdminsIndex,
  'Platform::Security::Twofactorauthentication::Reset': PlatformSecurityTwoFactorAuthenticationReset,
  'Platform::Settings::Security::Twofactorauthentication::Configuration': PlatformSettingsSecurityTwoFactorAuthenticationConfiguration,
  'Referrals::Availableroles::Availableroles': ReferralsAvailableRolesAvailableRoles,
  'Referrals::Jobs::Index': ReferralsJobsIndex,
  'Referrals::Layout::Mobilenavigation': ReferralsLayoutMobileNavigation,
  'Referrals::Myreferrals::Myreferrals': ReferralsMyReferralsMyReferrals,
  'Referrals::Settings::Settings': ReferralsSettingsSettings,
  'Referrals::Users::Invitations::Setpassword': ReferralsUsersInvitationsSetPassword,
  'Referrals::Users::Registrations::Signup': ReferralsUsersRegistrationsSignUp,
  'Shared::Breadcrumbs': SharedBreadcrumbs,
  'Shared::Intercomeventtracking': SharedIntercomEventTracking,
  'Shared::Mobileactionsbar': SharedMobileActionsBar,
  'Shared::Reactselectfilters': SharedReactSelectFilters,
  'Shared::Search': SharedSearch,
  'Shared::Thumbtackselect': SharedThumbtackSelect,
  'Shared::Tsselect': SharedTsSelect,
  'Shared::Form::Address': SharedFormAddress,
  'Shared::Form::Filefield': SharedFormFileField,
  'Shared::Form::Forminputgroup': SharedFormFormInputGroup,
  'Shared::Form::Jobpreferences': SharedFormJobPreferences,
  'Shared::Form::Multiplechoiceselectfield': SharedFormMultipleChoiceSelectField,
  'Shared::Form::Phonenumberinput': SharedFormPhoneNumberInput,
  'Shared::Form::Selectfield': SharedFormSelectField,
  'Shared::Form::Structuredsectionresponses': SharedFormStructuredSectionResponses,
  'Shared::Form::Urlinput': SharedFormUrlInput,
  'Shared::Form::Questions::Boolean': SharedFormQuestionsBoolean,
  'Shared::Form::Questions::Dateselect': SharedFormQuestionsDateSelect,
  'Shared::Form::Questions::Document': SharedFormQuestionsDocument,
  'Shared::Form::Questions::Longtext': SharedFormQuestionsLongText,
  'Shared::Form::Questions::Multiselect': SharedFormQuestionsMultiSelect,
  'Shared::Form::Questions::Multipledocuments': SharedFormQuestionsMultipleDocuments,
  'Shared::Form::Questions::Numberinput': SharedFormQuestionsNumberInput,
  'Shared::Form::Questions::Shorttext': SharedFormQuestionsShortText,
  'Shared::Form::Questions::Singleselect': SharedFormQuestionsSingleSelect,
  'Shared::Form::Questions::Url': SharedFormQuestionsUrl,
  'Shared::Google::Map': SharedGoogleMap,
  'Shared::Layout::Companydropdownmenu': SharedLayoutCompanyDropdownMenu,
  'Shared::Navigation::Backlink': SharedNavigationBackLink,
  'Shared::Navigation::Navigation': SharedNavigationNavigation,
  'Shared::Navigation::Primarysidebarnavigation': SharedNavigationPrimarySidebarNavigation,
  'Shared::Navigation::Secondarysidebarnavigation': SharedNavigationSecondarySidebarNavigation,
  'Shared::Thumbtack::Callout::Simplecallout': SharedThumbtackCalloutSimpleCallout,
  'Shared::Thumbtack::Form::Timezonepicker': SharedThumbtackFormTimeZonePicker,
  'Shared::Thumbtack::Form::Fields::Uncontrolleddatefield': SharedThumbtackFormFieldsUncontrolledDateField,
  'Shared::Thumbtack::Textcopy::Textcopy': SharedThumbtackTextCopyTextCopy,
  'Shared::Thumbtack::Toasts::Flashtoaster': SharedThumbtackToastsFlashToaster,
  'TwoFactorAuthentication::Recover': TwoFactorAuthenticationRecover,
};
